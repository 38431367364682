* {
  font-family: 'Candy Beans', sans-serif;
}

@import url('https://fonts.cdnfonts.com/css/candy-beans');
@import url('https://fonts.cdnfonts.com/css/liquidism-part-2');

.container2 {
  width: 100%;
  height: 100vh;
}

.circle-container {
  position: fixed;
  transform: translateY(-10vh);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  height: 0;
}

.circle-container .circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  mix-blend-mode: screen;
  background-image: radial-gradient(#9bf8ff, rgba(0, 132, 255, 0.534) 10%, rgba(153, 255, 255, 0) 56%);
  animation: fadein-frames 200ms infinite, scale-frames 2s infinite;
}


@keyframes fade-frames {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

@keyframes scale-frames {
  0% {
    transform: scale3d(0.4, 0.4, 1);
  }

  50% {
    transform: scale3d(2.2, 2.2, 1);
  }

  100% {
    transform: scale3d(0.4, 0.4, 1);
  }
}

.circle-container:nth-child(1) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-1;
  animation-duration: 29142ms;
  animation-delay: 19846ms;
}

@keyframes move-frames-1 {
  from {
    transform: translate3d(48vw, 101vh, 0);
  }

  to {
    transform: translate3d(35vw, -102vh, 0);
  }
}

.circle-container:nth-child(1) .circle {
  animation-delay: 2519ms;
}

.circle-container:nth-child(2) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-2;
  animation-duration: 36368ms;
  animation-delay: 19125ms;
}

@keyframes move-frames-2 {
  from {
    transform: translate3d(36vw, 107vh, 0);
  }

  to {
    transform: translate3d(68vw, -128vh, 0);
  }
}

.circle-container:nth-child(2) .circle {
  animation-delay: 1084ms;
}

.circle-container:nth-child(3) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-3;
  animation-duration: 33108ms;
  animation-delay: 1936ms;
}

@keyframes move-frames-3 {
  from {
    transform: translate3d(51vw, 104vh, 0);
  }

  to {
    transform: translate3d(92vw, -124vh, 0);
  }
}

.circle-container:nth-child(3) .circle {
  animation-delay: 1406ms;
}

.circle-container:nth-child(4) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-4;
  animation-duration: 28877ms;
  animation-delay: 30762ms;
}

@keyframes move-frames-4 {
  from {
    transform: translate3d(50vw, 108vh, 0);
  }

  to {
    transform: translate3d(57vw, -113vh, 0);
  }
}

.circle-container:nth-child(4) .circle {
  animation-delay: 2955ms;
}

.circle-container:nth-child(5) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-5;
  animation-duration: 35824ms;
  animation-delay: 31074ms;
}

@keyframes move-frames-5 {
  from {
    transform: translate3d(50vw, 108vh, 0);
  }

  to {
    transform: translate3d(31vw, -124vh, 0);
  }
}

.circle-container:nth-child(5) .circle {
  animation-delay: 1469ms;
}

.circle-container:nth-child(6) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-6;
  animation-duration: 32429ms;
  animation-delay: 35335ms;
}

@keyframes move-frames-6 {
  from {
    transform: translate3d(31vw, 101vh, 0);
  }

  to {
    transform: translate3d(86vw, -116vh, 0);
  }
}

.circle-container:nth-child(6) .circle {
  animation-delay: 653ms;
}

.circle-container:nth-child(7) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-7;
  animation-duration: 31901ms;
  animation-delay: 31819ms;
}

@keyframes move-frames-7 {
  from {
    transform: translate3d(77vw, 101vh, 0);
  }

  to {
    transform: translate3d(43vw, -110vh, 0);
  }
}

.circle-container:nth-child(7) .circle {
  animation-delay: 2166ms;
}

.circle-container:nth-child(8) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-8;
  animation-duration: 28634ms;
  animation-delay: 18091ms;
}

@keyframes move-frames-8 {
  from {
    transform: translate3d(81vw, 105vh, 0);
  }

  to {
    transform: translate3d(20vw, -119vh, 0);
  }
}

.circle-container:nth-child(8) .circle {
  animation-delay: 1433ms;
}

.circle-container:nth-child(9) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-9;
  animation-duration: 31744ms;
  animation-delay: 15233ms;
}

@keyframes move-frames-9 {
  from {
    transform: translate3d(80vw, 101vh, 0);
  }

  to {
    transform: translate3d(54vw, -118vh, 0);
  }
}

.circle-container:nth-child(9) .circle {
  animation-delay: 2399ms;
}

.circle-container:nth-child(10) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-10;
  animation-duration: 29461ms;
  animation-delay: 5651ms;
}

@keyframes move-frames-10 {
  from {
    transform: translate3d(24vw, 109vh, 0);
  }

  to {
    transform: translate3d(35vw, -130vh, 0);
  }
}

.circle-container:nth-child(10) .circle {
  animation-delay: 1080ms;
}

.circle-container:nth-child(11) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-11;
  animation-duration: 30609ms;
  animation-delay: 34893ms;
}

@keyframes move-frames-11 {
  from {
    transform: translate3d(16vw, 103vh, 0);
  }

  to {
    transform: translate3d(94vw, -124vh, 0);
  }
}

.circle-container:nth-child(11) .circle {
  animation-delay: 1477ms;
}

.circle-container:nth-child(12) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-12;
  animation-duration: 29877ms;
  animation-delay: 33535ms;
}

@keyframes move-frames-12 {
  from {
    transform: translate3d(25vw, 107vh, 0);
  }

  to {
    transform: translate3d(32vw, -110vh, 0);
  }
}

.circle-container:nth-child(12) .circle {
  animation-delay: 1105ms;
}

.circle-container:nth-child(13) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-13;
  animation-duration: 34198ms;
  animation-delay: 21871ms;
}

@keyframes move-frames-13 {
  from {
    transform: translate3d(59vw, 101vh, 0);
  }

  to {
    transform: translate3d(92vw, -113vh, 0);
  }
}

.circle-container:nth-child(13) .circle {
  animation-delay: 3982ms;
}

.circle-container:nth-child(14) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-14;
  animation-duration: 31477ms;
  animation-delay: 23360ms;
}

@keyframes move-frames-14 {
  from {
    transform: translate3d(27vw, 105vh, 0);
  }

  to {
    transform: translate3d(46vw, -125vh, 0);
  }
}

.circle-container:nth-child(14) .circle {
  animation-delay: 1912ms;
}

.circle-container:nth-child(15) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-15;
  animation-duration: 35088ms;
  animation-delay: 19665ms;
}

@keyframes move-frames-15 {
  from {
    transform: translate3d(64vw, 104vh, 0);
  }

  to {
    transform: translate3d(93vw, -118vh, 0);
  }
}

.circle-container:nth-child(15) .circle {
  animation-delay: 1404ms;
}

.circle-container:nth-child(16) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-16;
  animation-duration: 31494ms;
  animation-delay: 1568ms;
}

@keyframes move-frames-16 {
  from {
    transform: translate3d(10vw, 109vh, 0);
  }

  to {
    transform: translate3d(21vw, -136vh, 0);
  }
}

.circle-container:nth-child(16) .circle {
  animation-delay: 3706ms;
}

.circle-container:nth-child(17) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-17;
  animation-duration: 33208ms;
  animation-delay: 30298ms;
}

@keyframes move-frames-17 {
  from {
    transform: translate3d(39vw, 101vh, 0);
  }

  to {
    transform: translate3d(75vw, -110vh, 0);
  }
}

.circle-container:nth-child(17) .circle {
  animation-delay: 309ms;
}

.circle-container:nth-child(18) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-18;
  animation-duration: 30460ms;
  animation-delay: 10375ms;
}

@keyframes move-frames-18 {
  from {
    transform: translate3d(59vw, 110vh, 0);
  }

  to {
    transform: translate3d(59vw, -138vh, 0);
  }
}

.circle-container:nth-child(18) .circle {
  animation-delay: 1454ms;
}

.circle-container:nth-child(19) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-19;
  animation-duration: 36863ms;
  animation-delay: 13714ms;
}

@keyframes move-frames-19 {
  from {
    transform: translate3d(74vw, 102vh, 0);
  }

  to {
    transform: translate3d(89vw, -117vh, 0);
  }
}

.circle-container:nth-child(19) .circle {
  animation-delay: 2304ms;
}

.circle-container:nth-child(20) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-20;
  animation-duration: 31143ms;
  animation-delay: 30049ms;
}

@keyframes move-frames-20 {
  from {
    transform: translate3d(62vw, 105vh, 0);
  }

  to {
    transform: translate3d(70vw, -126vh, 0);
  }
}

.circle-container:nth-child(20) .circle {
  animation-delay: 923ms;
}

.circle-container:nth-child(21) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-21;
  animation-duration: 29000ms;
  animation-delay: 33203ms;
}

@keyframes move-frames-21 {
  from {
    transform: translate3d(78vw, 103vh, 0);
  }

  to {
    transform: translate3d(48vw, -131vh, 0);
  }
}

.circle-container:nth-child(21) .circle {
  animation-delay: 2225ms;
}

.circle-container:nth-child(22) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-22;
  animation-duration: 36652ms;
  animation-delay: 17612ms;
}

@keyframes move-frames-22 {
  from {
    transform: translate3d(80vw, 104vh, 0);
  }

  to {
    transform: translate3d(74vw, -114vh, 0);
  }
}

.circle-container:nth-child(22) .circle {
  animation-delay: 1317ms;
}

.circle-container:nth-child(23) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-23;
  animation-duration: 34731ms;
  animation-delay: 22173ms;
}

@keyframes move-frames-23 {
  from {
    transform: translate3d(96vw, 109vh, 0);
  }

  to {
    transform: translate3d(88vw, -135vh, 0);
  }
}

.circle-container:nth-child(23) .circle {
  animation-delay: 3929ms;
}

.circle-container:nth-child(24) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-24;
  animation-duration: 32220ms;
  animation-delay: 3198ms;
}

@keyframes move-frames-24 {
  from {
    transform: translate3d(58vw, 102vh, 0);
  }

  to {
    transform: translate3d(50vw, -109vh, 0);
  }
}

.circle-container:nth-child(24) .circle {
  animation-delay: 91ms;
}

.circle-container:nth-child(25) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-25;
  animation-duration: 35525ms;
  animation-delay: 29589ms;
}

@keyframes move-frames-25 {
  from {
    transform: translate3d(68vw, 109vh, 0);
  }

  to {
    transform: translate3d(10vw, -115vh, 0);
  }
}

.circle-container:nth-child(25) .circle {
  animation-delay: 2158ms;
}

.circle-container:nth-child(26) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-26;
  animation-duration: 35875ms;
  animation-delay: 33656ms;
}

@keyframes move-frames-26 {
  from {
    transform: translate3d(44vw, 109vh, 0);
  }

  to {
    transform: translate3d(9vw, -135vh, 0);
  }
}

.circle-container:nth-child(26) .circle {
  animation-delay: 456ms;
}

.circle-container:nth-child(27) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-27;
  animation-duration: 34577ms;
  animation-delay: 12215ms;
}

@keyframes move-frames-27 {
  from {
    transform: translate3d(85vw, 108vh, 0);
  }

  to {
    transform: translate3d(92vw, -116vh, 0);
  }
}

.circle-container:nth-child(27) .circle {
  animation-delay: 1184ms;
}

.circle-container:nth-child(28) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-28;
  animation-duration: 31908ms;
  animation-delay: 26869ms;
}

@keyframes move-frames-28 {
  from {
    transform: translate3d(41vw, 105vh, 0);
  }

  to {
    transform: translate3d(24vw, -118vh, 0);
  }
}

.circle-container:nth-child(28) .circle {
  animation-delay: 673ms;
}

.circle-container:nth-child(29) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-29;
  animation-duration: 33853ms;
  animation-delay: 288ms;
}

@keyframes move-frames-29 {
  from {
    transform: translate3d(26vw, 110vh, 0);
  }

  to {
    transform: translate3d(50vw, -128vh, 0);
  }
}

.circle-container:nth-child(29) .circle {
  animation-delay: 676ms;
}

.circle-container:nth-child(30) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-30;
  animation-duration: 32724ms;
  animation-delay: 9766ms;
}

@keyframes move-frames-30 {
  from {
    transform: translate3d(50vw, 108vh, 0);
  }

  to {
    transform: translate3d(52vw, -129vh, 0);
  }
}

.circle-container:nth-child(30) .circle {
  animation-delay: 3712ms;
}

.circle-container:nth-child(31) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-31;
  animation-duration: 30306ms;
  animation-delay: 4355ms;
}

@keyframes move-frames-31 {
  from {
    transform: translate3d(52vw, 109vh, 0);
  }

  to {
    transform: translate3d(55vw, -131vh, 0);
  }
}

.circle-container:nth-child(31) .circle {
  animation-delay: 1652ms;
}

.circle-container:nth-child(32) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-32;
  animation-duration: 36466ms;
  animation-delay: 23222ms;
}

@keyframes move-frames-32 {
  from {
    transform: translate3d(48vw, 107vh, 0);
  }

  to {
    transform: translate3d(33vw, -128vh, 0);
  }
}

.circle-container:nth-child(32) .circle {
  animation-delay: 2661ms;
}

.circle-container:nth-child(33) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-33;
  animation-duration: 28746ms;
  animation-delay: 31176ms;
}

@keyframes move-frames-33 {
  from {
    transform: translate3d(21vw, 109vh, 0);
  }

  to {
    transform: translate3d(28vw, -125vh, 0);
  }
}

.circle-container:nth-child(33) .circle {
  animation-delay: 1657ms;
}

.circle-container:nth-child(34) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-34;
  animation-duration: 36366ms;
  animation-delay: 10018ms;
}

@keyframes move-frames-34 {
  from {
    transform: translate3d(72vw, 106vh, 0);
  }

  to {
    transform: translate3d(69vw, -123vh, 0);
  }
}

.circle-container:nth-child(34) .circle {
  animation-delay: 3772ms;
}

.circle-container:nth-child(35) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-35;
  animation-duration: 29767ms;
  animation-delay: 22107ms;
}

@keyframes move-frames-35 {
  from {
    transform: translate3d(34vw, 105vh, 0);
  }

  to {
    transform: translate3d(53vw, -126vh, 0);
  }
}

.circle-container:nth-child(35) .circle {
  animation-delay: 3793ms;
}

.circle-container:nth-child(36) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-36;
  animation-duration: 33419ms;
  animation-delay: 28077ms;
}

@keyframes move-frames-36 {
  from {
    transform: translate3d(29vw, 104vh, 0);
  }

  to {
    transform: translate3d(90vw, -119vh, 0);
  }
}

.circle-container:nth-child(36) .circle {
  animation-delay: 2534ms;
}

.circle-container:nth-child(37) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-37;
  animation-duration: 30556ms;
  animation-delay: 30570ms;
}

@keyframes move-frames-37 {
  from {
    transform: translate3d(46vw, 105vh, 0);
  }

  to {
    transform: translate3d(27vw, -133vh, 0);
  }
}

.circle-container:nth-child(37) .circle {
  animation-delay: 323ms;
}

.circle-container:nth-child(38) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-38;
  animation-duration: 33679ms;
  animation-delay: 27547ms;
}

@keyframes move-frames-38 {
  from {
    transform: translate3d(74vw, 108vh, 0);
  }

  to {
    transform: translate3d(20vw, -123vh, 0);
  }
}

.circle-container:nth-child(38) .circle {
  animation-delay: 3503ms;
}

.circle-container:nth-child(39) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-39;
  animation-duration: 36428ms;
  animation-delay: 130ms;
}

@keyframes move-frames-39 {
  from {
    transform: translate3d(92vw, 104vh, 0);
  }

  to {
    transform: translate3d(99vw, -120vh, 0);
  }
}

.circle-container:nth-child(39) .circle {
  animation-delay: 2613ms;
}

.circle-container:nth-child(40) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-40;
  animation-duration: 36739ms;
  animation-delay: 33514ms;
}

@keyframes move-frames-40 {
  from {
    transform: translate3d(74vw, 106vh, 0);
  }

  to {
    transform: translate3d(49vw, -132vh, 0);
  }
}

.circle-container:nth-child(40) .circle {
  animation-delay: 2325ms;
}

.circle-container:nth-child(41) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-41;
  animation-duration: 30196ms;
  animation-delay: 20811ms;
}

@keyframes move-frames-41 {
  from {
    transform: translate3d(45vw, 106vh, 0);
  }

  to {
    transform: translate3d(56vw, -121vh, 0);
  }
}

.circle-container:nth-child(41) .circle {
  animation-delay: 3688ms;
}

.circle-container:nth-child(42) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-42;
  animation-duration: 36980ms;
  animation-delay: 20720ms;
}

@keyframes move-frames-42 {
  from {
    transform: translate3d(89vw, 109vh, 0);
  }

  to {
    transform: translate3d(33vw, -123vh, 0);
  }
}

.circle-container:nth-child(42) .circle {
  animation-delay: 744ms;
}

.circle-container:nth-child(43) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-43;
  animation-duration: 34954ms;
  animation-delay: 28366ms;
}

@keyframes move-frames-43 {
  from {
    transform: translate3d(12vw, 106vh, 0);
  }

  to {
    transform: translate3d(47vw, -136vh, 0);
  }
}

.circle-container:nth-child(43) .circle {
  animation-delay: 763ms;
}

.circle-container:nth-child(44) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-44;
  animation-duration: 35359ms;
  animation-delay: 8123ms;
}

@keyframes move-frames-44 {
  from {
    transform: translate3d(31vw, 104vh, 0);
  }

  to {
    transform: translate3d(73vw, -124vh, 0);
  }
}

.circle-container:nth-child(44) .circle {
  animation-delay: 2923ms;
}

.circle-container:nth-child(45) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-45;
  animation-duration: 32140ms;
  animation-delay: 3024ms;
}

@keyframes move-frames-45 {
  from {
    transform: translate3d(11vw, 110vh, 0);
  }

  to {
    transform: translate3d(89vw, -133vh, 0);
  }
}

.circle-container:nth-child(45) .circle {
  animation-delay: 1863ms;
}

.circle-container:nth-child(46) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-46;
  animation-duration: 28466ms;
  animation-delay: 11332ms;
}

@keyframes move-frames-46 {
  from {
    transform: translate3d(87vw, 102vh, 0);
  }

  to {
    transform: translate3d(82vw, -108vh, 0);
  }
}

.circle-container:nth-child(46) .circle {
  animation-delay: 1494ms;
}

.circle-container:nth-child(47) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-47;
  animation-duration: 30852ms;
  animation-delay: 26331ms;
}

@keyframes move-frames-47 {
  from {
    transform: translate3d(95vw, 101vh, 0);
  }

  to {
    transform: translate3d(97vw, -105vh, 0);
  }
}

.circle-container:nth-child(47) .circle {
  animation-delay: 3888ms;
}

.circle-container:nth-child(48) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-48;
  animation-duration: 32146ms;
  animation-delay: 33093ms;
}

@keyframes move-frames-48 {
  from {
    transform: translate3d(48vw, 102vh, 0);
  }

  to {
    transform: translate3d(45vw, -106vh, 0);
  }
}

.circle-container:nth-child(48) .circle {
  animation-delay: 1095ms;
}

.circle-container:nth-child(49) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-49;
  animation-duration: 36524ms;
  animation-delay: 5829ms;
}

@keyframes move-frames-49 {
  from {
    transform: translate3d(22vw, 102vh, 0);
  }

  to {
    transform: translate3d(51vw, -127vh, 0);
  }
}

.circle-container:nth-child(49) .circle {
  animation-delay: 1664ms;
}

.circle-container:nth-child(50) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-50;
  animation-duration: 32664ms;
  animation-delay: 20738ms;
}

@keyframes move-frames-50 {
  from {
    transform: translate3d(21vw, 104vh, 0);
  }

  to {
    transform: translate3d(73vw, -107vh, 0);
  }
}

.circle-container:nth-child(50) .circle {
  animation-delay: 2185ms;
}

.circle-container:nth-child(51) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-51;
  animation-duration: 35935ms;
  animation-delay: 34643ms;
}

@keyframes move-frames-51 {
  from {
    transform: translate3d(11vw, 105vh, 0);
  }

  to {
    transform: translate3d(55vw, -117vh, 0);
  }
}

.circle-container:nth-child(51) .circle {
  animation-delay: 3170ms;
}

.circle-container:nth-child(52) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-52;
  animation-duration: 35002ms;
  animation-delay: 9255ms;
}

@keyframes move-frames-52 {
  from {
    transform: translate3d(37vw, 107vh, 0);
  }

  to {
    transform: translate3d(27vw, -119vh, 0);
  }
}

.circle-container:nth-child(52) .circle {
  animation-delay: 2869ms;
}

.circle-container:nth-child(53) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-53;
  animation-duration: 31411ms;
  animation-delay: 5029ms;
}

@keyframes move-frames-53 {
  from {
    transform: translate3d(100vw, 109vh, 0);
  }

  to {
    transform: translate3d(3vw, -138vh, 0);
  }
}

.circle-container:nth-child(53) .circle {
  animation-delay: 2710ms;
}

.circle-container:nth-child(54) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-54;
  animation-duration: 34484ms;
  animation-delay: 3960ms;
}

@keyframes move-frames-54 {
  from {
    transform: translate3d(59vw, 102vh, 0);
  }

  to {
    transform: translate3d(99vw, -132vh, 0);
  }
}

.circle-container:nth-child(54) .circle {
  animation-delay: 932ms;
}

.circle-container:nth-child(55) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-55;
  animation-duration: 30870ms;
  animation-delay: 8766ms;
}

@keyframes move-frames-55 {
  from {
    transform: translate3d(17vw, 107vh, 0);
  }

  to {
    transform: translate3d(87vw, -137vh, 0);
  }
}

.circle-container:nth-child(55) .circle {
  animation-delay: 2816ms;
}

.circle-container:nth-child(56) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-56;
  animation-duration: 31465ms;
  animation-delay: 15049ms;
}

@keyframes move-frames-56 {
  from {
    transform: translate3d(11vw, 104vh, 0);
  }

  to {
    transform: translate3d(59vw, -106vh, 0);
  }
}

.circle-container:nth-child(56) .circle {
  animation-delay: 502ms;
}

.circle-container:nth-child(57) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-57;
  animation-duration: 34746ms;
  animation-delay: 24610ms;
}

@keyframes move-frames-57 {
  from {
    transform: translate3d(8vw, 106vh, 0);
  }

  to {
    transform: translate3d(6vw, -113vh, 0);
  }
}

.circle-container:nth-child(57) .circle {
  animation-delay: 903ms;
}

.circle-container:nth-child(58) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-58;
  animation-duration: 31745ms;
  animation-delay: 23189ms;
}

@keyframes move-frames-58 {
  from {
    transform: translate3d(49vw, 106vh, 0);
  }

  to {
    transform: translate3d(57vw, -128vh, 0);
  }
}

.circle-container:nth-child(58) .circle {
  animation-delay: 3601ms;
}

.circle-container:nth-child(59) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-59;
  animation-duration: 30816ms;
  animation-delay: 31745ms;
}

@keyframes move-frames-59 {
  from {
    transform: translate3d(59vw, 105vh, 0);
  }

  to {
    transform: translate3d(61vw, -123vh, 0);
  }
}

.circle-container:nth-child(59) .circle {
  animation-delay: 1368ms;
}

.circle-container:nth-child(60) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-60;
  animation-duration: 33647ms;
  animation-delay: 5961ms;
}

@keyframes move-frames-60 {
  from {
    transform: translate3d(88vw, 106vh, 0);
  }

  to {
    transform: translate3d(18vw, -126vh, 0);
  }
}

.circle-container:nth-child(60) .circle {
  animation-delay: 679ms;
}

.circle-container:nth-child(61) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-61;
  animation-duration: 31448ms;
  animation-delay: 29691ms;
}

@keyframes move-frames-61 {
  from {
    transform: translate3d(82vw, 106vh, 0);
  }

  to {
    transform: translate3d(15vw, -118vh, 0);
  }
}

.circle-container:nth-child(61) .circle {
  animation-delay: 1833ms;
}

.circle-container:nth-child(62) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-62;
  animation-duration: 28308ms;
  animation-delay: 19263ms;
}

@keyframes move-frames-62 {
  from {
    transform: translate3d(55vw, 104vh, 0);
  }

  to {
    transform: translate3d(86vw, -128vh, 0);
  }
}

.circle-container:nth-child(62) .circle {
  animation-delay: 3453ms;
}

.circle-container:nth-child(63) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-63;
  animation-duration: 30972ms;
  animation-delay: 3844ms;
}

@keyframes move-frames-63 {
  from {
    transform: translate3d(49vw, 101vh, 0);
  }

  to {
    transform: translate3d(87vw, -107vh, 0);
  }
}

.circle-container:nth-child(63) .circle {
  animation-delay: 626ms;
}

.circle-container:nth-child(64) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-64;
  animation-duration: 33170ms;
  animation-delay: 144ms;
}

@keyframes move-frames-64 {
  from {
    transform: translate3d(41vw, 105vh, 0);
  }

  to {
    transform: translate3d(34vw, -111vh, 0);
  }
}

.circle-container:nth-child(64) .circle {
  animation-delay: 1099ms;
}

.circle-container:nth-child(65) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-65;
  animation-duration: 36461ms;
  animation-delay: 851ms;
}

@keyframes move-frames-65 {
  from {
    transform: translate3d(25vw, 103vh, 0);
  }

  to {
    transform: translate3d(79vw, -131vh, 0);
  }
}

.circle-container:nth-child(65) .circle {
  animation-delay: 2827ms;
}

.circle-container:nth-child(66) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-66;
  animation-duration: 32334ms;
  animation-delay: 21586ms;
}

@keyframes move-frames-66 {
  from {
    transform: translate3d(52vw, 107vh, 0);
  }

  to {
    transform: translate3d(44vw, -133vh, 0);
  }
}

.circle-container:nth-child(66) .circle {
  animation-delay: 3780ms;
}

.circle-container:nth-child(67) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-67;
  animation-duration: 34301ms;
  animation-delay: 34104ms;
}

@keyframes move-frames-67 {
  from {
    transform: translate3d(77vw, 101vh, 0);
  }

  to {
    transform: translate3d(29vw, -113vh, 0);
  }
}

.circle-container:nth-child(67) .circle {
  animation-delay: 26ms;
}

.circle-container:nth-child(68) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-68;
  animation-duration: 34599ms;
  animation-delay: 36245ms;
}

@keyframes move-frames-68 {
  from {
    transform: translate3d(74vw, 101vh, 0);
  }

  to {
    transform: translate3d(86vw, -103vh, 0);
  }
}

.circle-container:nth-child(68) .circle {
  animation-delay: 2759ms;
}

.circle-container:nth-child(69) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-69;
  animation-duration: 36460ms;
  animation-delay: 9061ms;
}

@keyframes move-frames-69 {
  from {
    transform: translate3d(39vw, 101vh, 0);
  }

  to {
    transform: translate3d(80vw, -122vh, 0);
  }
}

.circle-container:nth-child(69) .circle {
  animation-delay: 2452ms;
}

.circle-container:nth-child(70) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-70;
  animation-duration: 33436ms;
  animation-delay: 36789ms;
}

@keyframes move-frames-70 {
  from {
    transform: translate3d(74vw, 108vh, 0);
  }

  to {
    transform: translate3d(37vw, -124vh, 0);
  }
}

.circle-container:nth-child(70) .circle {
  animation-delay: 3893ms;
}

.circle-container:nth-child(71) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-71;
  animation-duration: 29878ms;
  animation-delay: 9226ms;
}

@keyframes move-frames-71 {
  from {
    transform: translate3d(62vw, 106vh, 0);
  }

  to {
    transform: translate3d(69vw, -135vh, 0);
  }
}

.circle-container:nth-child(71) .circle {
  animation-delay: 2395ms;
}

.circle-container:nth-child(72) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-72;
  animation-duration: 36655ms;
  animation-delay: 33632ms;
}

@keyframes move-frames-72 {
  from {
    transform: translate3d(41vw, 103vh, 0);
  }

  to {
    transform: translate3d(3vw, -117vh, 0);
  }
}

.circle-container:nth-child(72) .circle {
  animation-delay: 2080ms;
}

.circle-container:nth-child(73) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-73;
  animation-duration: 34472ms;
  animation-delay: 26092ms;
}

@keyframes move-frames-73 {
  from {
    transform: translate3d(12vw, 109vh, 0);
  }

  to {
    transform: translate3d(24vw, -134vh, 0);
  }
}

.circle-container:nth-child(73) .circle {
  animation-delay: 313ms;
}

.circle-container:nth-child(74) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-74;
  animation-duration: 31693ms;
  animation-delay: 27190ms;
}

@keyframes move-frames-74 {
  from {
    transform: translate3d(28vw, 102vh, 0);
  }

  to {
    transform: translate3d(71vw, -120vh, 0);
  }
}

.circle-container:nth-child(74) .circle {
  animation-delay: 938ms;
}

.circle-container:nth-child(75) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-75;
  animation-duration: 34065ms;
  animation-delay: 18116ms;
}

@keyframes move-frames-75 {
  from {
    transform: translate3d(42vw, 104vh, 0);
  }

  to {
    transform: translate3d(99vw, -107vh, 0);
  }
}

.circle-container:nth-child(75) .circle {
  animation-delay: 588ms;
}

.circle-container:nth-child(76) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-76;
  animation-duration: 30502ms;
  animation-delay: 26354ms;
}

@keyframes move-frames-76 {
  from {
    transform: translate3d(36vw, 104vh, 0);
  }

  to {
    transform: translate3d(89vw, -112vh, 0);
  }
}

.circle-container:nth-child(76) .circle {
  animation-delay: 2460ms;
}

.circle-container:nth-child(77) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-77;
  animation-duration: 31963ms;
  animation-delay: 23345ms;
}

@keyframes move-frames-77 {
  from {
    transform: translate3d(63vw, 105vh, 0);
  }

  to {
    transform: translate3d(94vw, -115vh, 0);
  }
}

.circle-container:nth-child(77) .circle {
  animation-delay: 1193ms;
}

.circle-container:nth-child(78) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-78;
  animation-duration: 30431ms;
  animation-delay: 22141ms;
}

@keyframes move-frames-78 {
  from {
    transform: translate3d(75vw, 109vh, 0);
  }

  to {
    transform: translate3d(16vw, -134vh, 0);
  }
}

.circle-container:nth-child(78) .circle {
  animation-delay: 3693ms;
}

.circle-container:nth-child(79) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-79;
  animation-duration: 36905ms;
  animation-delay: 20196ms;
}

@keyframes move-frames-79 {
  from {
    transform: translate3d(45vw, 102vh, 0);
  }

  to {
    transform: translate3d(67vw, -104vh, 0);
  }
}

.circle-container:nth-child(79) .circle {
  animation-delay: 3138ms;
}

.circle-container:nth-child(80) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-80;
  animation-duration: 33414ms;
  animation-delay: 28543ms;
}

@keyframes move-frames-80 {
  from {
    transform: translate3d(76vw, 109vh, 0);
  }

  to {
    transform: translate3d(90vw, -126vh, 0);
  }
}

.circle-container:nth-child(80) .circle {
  animation-delay: 2014ms;
}

.circle-container:nth-child(81) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-81;
  animation-duration: 34416ms;
  animation-delay: 26911ms;
}

@keyframes move-frames-81 {
  from {
    transform: translate3d(7vw, 103vh, 0);
  }

  to {
    transform: translate3d(67vw, -115vh, 0);
  }
}

.circle-container:nth-child(81) .circle {
  animation-delay: 3870ms;
}

.circle-container:nth-child(82) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-82;
  animation-duration: 29375ms;
  animation-delay: 36449ms;
}

@keyframes move-frames-82 {
  from {
    transform: translate3d(50vw, 109vh, 0);
  }

  to {
    transform: translate3d(87vw, -127vh, 0);
  }
}

.circle-container:nth-child(82) .circle {
  animation-delay: 1207ms;
}

.circle-container:nth-child(83) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-83;
  animation-duration: 34734ms;
  animation-delay: 2453ms;
}

@keyframes move-frames-83 {
  from {
    transform: translate3d(75vw, 108vh, 0);
  }

  to {
    transform: translate3d(23vw, -125vh, 0);
  }
}

.circle-container:nth-child(83) .circle {
  animation-delay: 3365ms;
}

.circle-container:nth-child(84) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-84;
  animation-duration: 32584ms;
  animation-delay: 22435ms;
}

@keyframes move-frames-84 {
  from {
    transform: translate3d(52vw, 106vh, 0);
  }

  to {
    transform: translate3d(17vw, -111vh, 0);
  }
}

.circle-container:nth-child(84) .circle {
  animation-delay: 2065ms;
}

.circle-container:nth-child(85) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-85;
  animation-duration: 30914ms;
  animation-delay: 4700ms;
}

@keyframes move-frames-85 {
  from {
    transform: translate3d(31vw, 103vh, 0);
  }

  to {
    transform: translate3d(29vw, -121vh, 0);
  }
}

.circle-container:nth-child(85) .circle {
  animation-delay: 471ms;
}

.circle-container:nth-child(86) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-86;
  animation-duration: 34295ms;
  animation-delay: 36675ms;
}

@keyframes move-frames-86 {
  from {
    transform: translate3d(78vw, 101vh, 0);
  }

  to {
    transform: translate3d(35vw, -117vh, 0);
  }
}

.circle-container:nth-child(86) .circle {
  animation-delay: 2669ms;
}

.circle-container:nth-child(87) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-87;
  animation-duration: 31904ms;
  animation-delay: 7428ms;
}

@keyframes move-frames-87 {
  from {
    transform: translate3d(30vw, 109vh, 0);
  }

  to {
    transform: translate3d(70vw, -132vh, 0);
  }
}

.circle-container:nth-child(87) .circle {
  animation-delay: 2520ms;
}

.circle-container:nth-child(88) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-88;
  animation-duration: 32334ms;
  animation-delay: 17801ms;
}

@keyframes move-frames-88 {
  from {
    transform: translate3d(39vw, 101vh, 0);
  }

  to {
    transform: translate3d(61vw, -107vh, 0);
  }
}

.circle-container:nth-child(88) .circle {
  animation-delay: 2175ms;
}

.circle-container:nth-child(89) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-89;
  animation-duration: 34126ms;
  animation-delay: 30329ms;
}

@keyframes move-frames-89 {
  from {
    transform: translate3d(71vw, 103vh, 0);
  }

  to {
    transform: translate3d(44vw, -125vh, 0);
  }
}

.circle-container:nth-child(89) .circle {
  animation-delay: 979ms;
}

.circle-container:nth-child(90) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-90;
  animation-duration: 30221ms;
  animation-delay: 27967ms;
}

@keyframes move-frames-90 {
  from {
    transform: translate3d(91vw, 110vh, 0);
  }

  to {
    transform: translate3d(43vw, -131vh, 0);
  }
}

.circle-container:nth-child(90) .circle {
  animation-delay: 3340ms;
}

.circle-container:nth-child(91) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-91;
  animation-duration: 33856ms;
  animation-delay: 22877ms;
}

@keyframes move-frames-91 {
  from {
    transform: translate3d(38vw, 101vh, 0);
  }

  to {
    transform: translate3d(50vw, -109vh, 0);
  }
}

.circle-container:nth-child(91) .circle {
  animation-delay: 2601ms;
}

.circle-container:nth-child(92) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-92;
  animation-duration: 33790ms;
  animation-delay: 25047ms;
}

@keyframes move-frames-92 {
  from {
    transform: translate3d(28vw, 105vh, 0);
  }

  to {
    transform: translate3d(67vw, -109vh, 0);
  }
}

.circle-container:nth-child(92) .circle {
  animation-delay: 995ms;
}

.circle-container:nth-child(93) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-93;
  animation-duration: 32289ms;
  animation-delay: 8081ms;
}

@keyframes move-frames-93 {
  from {
    transform: translate3d(7vw, 109vh, 0);
  }

  to {
    transform: translate3d(25vw, -129vh, 0);
  }
}

.circle-container:nth-child(93) .circle {
  animation-delay: 1297ms;
}

.circle-container:nth-child(94) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-94;
  animation-duration: 36464ms;
  animation-delay: 35818ms;
}

@keyframes move-frames-94 {
  from {
    transform: translate3d(98vw, 102vh, 0);
  }

  to {
    transform: translate3d(73vw, -104vh, 0);
  }
}

.circle-container:nth-child(94) .circle {
  animation-delay: 1388ms;
}

.circle-container:nth-child(95) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-95;
  animation-duration: 35765ms;
  animation-delay: 21102ms;
}

@keyframes move-frames-95 {
  from {
    transform: translate3d(39vw, 104vh, 0);
  }

  to {
    transform: translate3d(36vw, -111vh, 0);
  }
}

.circle-container:nth-child(95) .circle {
  animation-delay: 2921ms;
}

.circle-container:nth-child(96) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-96;
  animation-duration: 30502ms;
  animation-delay: 27214ms;
}

@keyframes move-frames-96 {
  from {
    transform: translate3d(15vw, 108vh, 0);
  }

  to {
    transform: translate3d(13vw, -136vh, 0);
  }
}

.circle-container:nth-child(96) .circle {
  animation-delay: 3485ms;
}

.circle-container:nth-child(97) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-97;
  animation-duration: 35275ms;
  animation-delay: 12588ms;
}

@keyframes move-frames-97 {
  from {
    transform: translate3d(42vw, 109vh, 0);
  }

  to {
    transform: translate3d(11vw, -122vh, 0);
  }
}

.circle-container:nth-child(97) .circle {
  animation-delay: 2892ms;
}

.circle-container:nth-child(98) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-98;
  animation-duration: 35993ms;
  animation-delay: 27568ms;
}

@keyframes move-frames-98 {
  from {
    transform: translate3d(18vw, 101vh, 0);
  }

  to {
    transform: translate3d(33vw, -119vh, 0);
  }
}

.circle-container:nth-child(98) .circle {
  animation-delay: 74ms;
}

.circle-container:nth-child(99) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-99;
  animation-duration: 28213ms;
  animation-delay: 33567ms;
}

@keyframes move-frames-99 {
  from {
    transform: translate3d(95vw, 106vh, 0);
  }

  to {
    transform: translate3d(79vw, -135vh, 0);
  }
}

.circle-container:nth-child(99) .circle {
  animation-delay: 198ms;
}

.circle-container:nth-child(100) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-100;
  animation-duration: 29405ms;
  animation-delay: 30490ms;
}

@keyframes move-frames-100 {
  from {
    transform: translate3d(5vw, 103vh, 0);
  }

  to {
    transform: translate3d(5vw, -110vh, 0);
  }
}

.circle-container:nth-child(100) .circle {
  animation-delay: 3981ms;
}

.circle-container:nth-child(101) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-101;
  animation-duration: 29968ms;
  animation-delay: 9125ms;
}

@keyframes move-frames-101 {
  from {
    transform: translate3d(85vw, 107vh, 0);
  }

  to {
    transform: translate3d(71vw, -110vh, 0);
  }
}

.circle-container:nth-child(101) .circle {
  animation-delay: 2201ms;
}

.circle-container:nth-child(102) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-102;
  animation-duration: 28916ms;
  animation-delay: 22698ms;
}

@keyframes move-frames-102 {
  from {
    transform: translate3d(37vw, 110vh, 0);
  }

  to {
    transform: translate3d(20vw, -120vh, 0);
  }
}

.circle-container:nth-child(102) .circle {
  animation-delay: 3872ms;
}

.circle-container:nth-child(103) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-103;
  animation-duration: 29503ms;
  animation-delay: 21791ms;
}

@keyframes move-frames-103 {
  from {
    transform: translate3d(86vw, 104vh, 0);
  }

  to {
    transform: translate3d(2vw, -134vh, 0);
  }
}

.circle-container:nth-child(103) .circle {
  animation-delay: 3570ms;
}

.circle-container:nth-child(104) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-104;
  animation-duration: 30734ms;
  animation-delay: 30465ms;
}

@keyframes move-frames-104 {
  from {
    transform: translate3d(98vw, 101vh, 0);
  }

  to {
    transform: translate3d(35vw, -109vh, 0);
  }
}

.circle-container:nth-child(104) .circle {
  animation-delay: 3690ms;
}

.circle-container:nth-child(105) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-105;
  animation-duration: 30204ms;
  animation-delay: 14424ms;
}

@keyframes move-frames-105 {
  from {
    transform: translate3d(25vw, 109vh, 0);
  }

  to {
    transform: translate3d(71vw, -126vh, 0);
  }
}

.circle-container:nth-child(105) .circle {
  animation-delay: 3170ms;
}

.circle-container:nth-child(106) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-106;
  animation-duration: 32148ms;
  animation-delay: 19165ms;
}

@keyframes move-frames-106 {
  from {
    transform: translate3d(84vw, 105vh, 0);
  }

  to {
    transform: translate3d(52vw, -128vh, 0);
  }
}

.circle-container:nth-child(106) .circle {
  animation-delay: 3842ms;
}

.circle-container:nth-child(107) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-107;
  animation-duration: 36943ms;
  animation-delay: 12405ms;
}

@keyframes move-frames-107 {
  from {
    transform: translate3d(16vw, 110vh, 0);
  }

  to {
    transform: translate3d(1vw, -112vh, 0);
  }
}

.circle-container:nth-child(107) .circle {
  animation-delay: 3906ms;
}

.circle-container:nth-child(108) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-108;
  animation-duration: 35467ms;
  animation-delay: 32037ms;
}

@keyframes move-frames-108 {
  from {
    transform: translate3d(56vw, 110vh, 0);
  }

  to {
    transform: translate3d(93vw, -118vh, 0);
  }
}

.circle-container:nth-child(108) .circle {
  animation-delay: 1682ms;
}

.circle-container:nth-child(109) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-109;
  animation-duration: 32289ms;
  animation-delay: 25940ms;
}

@keyframes move-frames-109 {
  from {
    transform: translate3d(46vw, 105vh, 0);
  }

  to {
    transform: translate3d(78vw, -133vh, 0);
  }
}

.circle-container:nth-child(109) .circle {
  animation-delay: 859ms;
}

.circle-container:nth-child(110) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-110;
  animation-duration: 33249ms;
  animation-delay: 11206ms;
}

@keyframes move-frames-110 {
  from {
    transform: translate3d(78vw, 107vh, 0);
  }

  to {
    transform: translate3d(42vw, -128vh, 0);
  }
}

.circle-container:nth-child(110) .circle {
  animation-delay: 1543ms;
}

.circle-container:nth-child(111) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-111;
  animation-duration: 32235ms;
  animation-delay: 22721ms;
}

@keyframes move-frames-111 {
  from {
    transform: translate3d(93vw, 107vh, 0);
  }

  to {
    transform: translate3d(72vw, -129vh, 0);
  }
}

.circle-container:nth-child(111) .circle {
  animation-delay: 926ms;
}

.circle-container:nth-child(112) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-112;
  animation-duration: 36584ms;
  animation-delay: 17442ms;
}

@keyframes move-frames-112 {
  from {
    transform: translate3d(66vw, 103vh, 0);
  }

  to {
    transform: translate3d(84vw, -128vh, 0);
  }
}

.circle-container:nth-child(112) .circle {
  animation-delay: 3359ms;
}

.circle-container:nth-child(113) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-113;
  animation-duration: 31260ms;
  animation-delay: 8370ms;
}

@keyframes move-frames-113 {
  from {
    transform: translate3d(46vw, 108vh, 0);
  }

  to {
    transform: translate3d(42vw, -132vh, 0);
  }
}

.circle-container:nth-child(113) .circle {
  animation-delay: 1133ms;
}

.circle-container:nth-child(114) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-114;
  animation-duration: 29389ms;
  animation-delay: 5792ms;
}

@keyframes move-frames-114 {
  from {
    transform: translate3d(3vw, 106vh, 0);
  }

  to {
    transform: translate3d(58vw, -126vh, 0);
  }
}

.circle-container:nth-child(114) .circle {
  animation-delay: 3600ms;
}

.circle-container:nth-child(115) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-115;
  animation-duration: 28611ms;
  animation-delay: 21873ms;
}

@keyframes move-frames-115 {
  from {
    transform: translate3d(26vw, 107vh, 0);
  }

  to {
    transform: translate3d(20vw, -123vh, 0);
  }
}

.circle-container:nth-child(115) .circle {
  animation-delay: 1609ms;
}

.circle-container:nth-child(116) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-116;
  animation-duration: 35599ms;
  animation-delay: 734ms;
}

@keyframes move-frames-116 {
  from {
    transform: translate3d(86vw, 107vh, 0);
  }

  to {
    transform: translate3d(41vw, -133vh, 0);
  }
}

.circle-container:nth-child(116) .circle {
  animation-delay: 1710ms;
}

.circle-container:nth-child(117) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-117;
  animation-duration: 35085ms;
  animation-delay: 32674ms;
}

@keyframes move-frames-117 {
  from {
    transform: translate3d(65vw, 107vh, 0);
  }

  to {
    transform: translate3d(86vw, -111vh, 0);
  }
}

.circle-container:nth-child(117) .circle {
  animation-delay: 391ms;
}

.circle-container:nth-child(118) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-118;
  animation-duration: 28426ms;
  animation-delay: 13214ms;
}

@keyframes move-frames-118 {
  from {
    transform: translate3d(88vw, 109vh, 0);
  }

  to {
    transform: translate3d(39vw, -131vh, 0);
  }
}

.circle-container:nth-child(118) .circle {
  animation-delay: 2889ms;
}

.circle-container:nth-child(119) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-119;
  animation-duration: 31179ms;
  animation-delay: 1360ms;
}

@keyframes move-frames-119 {
  from {
    transform: translate3d(17vw, 109vh, 0);
  }

  to {
    transform: translate3d(86vw, -118vh, 0);
  }
}

.circle-container:nth-child(119) .circle {
  animation-delay: 1297ms;
}

.circle-container:nth-child(120) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-120;
  animation-duration: 35514ms;
  animation-delay: 23725ms;
}

@keyframes move-frames-120 {
  from {
    transform: translate3d(70vw, 106vh, 0);
  }

  to {
    transform: translate3d(37vw, -122vh, 0);
  }
}

.circle-container:nth-child(120) .circle {
  animation-delay: 1030ms;
}

.circle-container:nth-child(121) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-121;
  animation-duration: 32154ms;
  animation-delay: 30916ms;
}

@keyframes move-frames-121 {
  from {
    transform: translate3d(69vw, 109vh, 0);
  }

  to {
    transform: translate3d(90vw, -117vh, 0);
  }
}

.circle-container:nth-child(121) .circle {
  animation-delay: 2214ms;
}

.circle-container:nth-child(122) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-122;
  animation-duration: 35331ms;
  animation-delay: 32463ms;
}

@keyframes move-frames-122 {
  from {
    transform: translate3d(47vw, 108vh, 0);
  }

  to {
    transform: translate3d(51vw, -125vh, 0);
  }
}

.circle-container:nth-child(122) .circle {
  animation-delay: 3707ms;
}

.circle-container:nth-child(123) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-123;
  animation-duration: 28684ms;
  animation-delay: 36500ms;
}

@keyframes move-frames-123 {
  from {
    transform: translate3d(44vw, 109vh, 0);
  }

  to {
    transform: translate3d(31vw, -122vh, 0);
  }
}

.circle-container:nth-child(123) .circle {
  animation-delay: 447ms;
}

.circle-container:nth-child(124) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-124;
  animation-duration: 36090ms;
  animation-delay: 7067ms;
}

@keyframes move-frames-124 {
  from {
    transform: translate3d(74vw, 107vh, 0);
  }

  to {
    transform: translate3d(89vw, -111vh, 0);
  }
}

.circle-container:nth-child(124) .circle {
  animation-delay: 2240ms;
}

.circle-container:nth-child(125) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-125;
  animation-duration: 28814ms;
  animation-delay: 9308ms;
}

@keyframes move-frames-125 {
  from {
    transform: translate3d(69vw, 108vh, 0);
  }

  to {
    transform: translate3d(78vw, -122vh, 0);
  }
}

.circle-container:nth-child(125) .circle {
  animation-delay: 69ms;
}

.circle-container:nth-child(126) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-126;
  animation-duration: 29080ms;
  animation-delay: 28145ms;
}

@keyframes move-frames-126 {
  from {
    transform: translate3d(99vw, 105vh, 0);
  }

  to {
    transform: translate3d(42vw, -112vh, 0);
  }
}

.circle-container:nth-child(126) .circle {
  animation-delay: 7ms;
}

.circle-container:nth-child(127) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-127;
  animation-duration: 28660ms;
  animation-delay: 33412ms;
}

@keyframes move-frames-127 {
  from {
    transform: translate3d(69vw, 102vh, 0);
  }

  to {
    transform: translate3d(23vw, -111vh, 0);
  }
}

.circle-container:nth-child(127) .circle {
  animation-delay: 2882ms;
}

.circle-container:nth-child(128) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-128;
  animation-duration: 31174ms;
  animation-delay: 24214ms;
}

@keyframes move-frames-128 {
  from {
    transform: translate3d(7vw, 106vh, 0);
  }

  to {
    transform: translate3d(23vw, -115vh, 0);
  }
}

.circle-container:nth-child(128) .circle {
  animation-delay: 3082ms;
}

.circle-container:nth-child(129) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-129;
  animation-duration: 32171ms;
  animation-delay: 34435ms;
}

@keyframes move-frames-129 {
  from {
    transform: translate3d(31vw, 101vh, 0);
  }

  to {
    transform: translate3d(34vw, -129vh, 0);
  }
}

.circle-container:nth-child(129) .circle {
  animation-delay: 872ms;
}

.circle-container:nth-child(130) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-130;
  animation-duration: 36636ms;
  animation-delay: 5949ms;
}

@keyframes move-frames-130 {
  from {
    transform: translate3d(46vw, 105vh, 0);
  }

  to {
    transform: translate3d(5vw, -130vh, 0);
  }
}

.circle-container:nth-child(130) .circle {
  animation-delay: 2219ms;
}

.circle-container:nth-child(131) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-131;
  animation-duration: 31452ms;
  animation-delay: 9541ms;
}

@keyframes move-frames-131 {
  from {
    transform: translate3d(71vw, 107vh, 0);
  }

  to {
    transform: translate3d(25vw, -126vh, 0);
  }
}

.circle-container:nth-child(131) .circle {
  animation-delay: 421ms;
}

.circle-container:nth-child(132) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-132;
  animation-duration: 29409ms;
  animation-delay: 5131ms;
}

@keyframes move-frames-132 {
  from {
    transform: translate3d(96vw, 110vh, 0);
  }

  to {
    transform: translate3d(16vw, -119vh, 0);
  }
}

.circle-container:nth-child(132) .circle {
  animation-delay: 2650ms;
}

.circle-container:nth-child(133) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-133;
  animation-duration: 34135ms;
  animation-delay: 22884ms;
}

@keyframes move-frames-133 {
  from {
    transform: translate3d(38vw, 103vh, 0);
  }

  to {
    transform: translate3d(9vw, -122vh, 0);
  }
}

.circle-container:nth-child(133) .circle {
  animation-delay: 2856ms;
}

.circle-container:nth-child(134) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-134;
  animation-duration: 28153ms;
  animation-delay: 13261ms;
}

@keyframes move-frames-134 {
  from {
    transform: translate3d(100vw, 105vh, 0);
  }

  to {
    transform: translate3d(10vw, -133vh, 0);
  }
}

.circle-container:nth-child(134) .circle {
  animation-delay: 3709ms;
}

.circle-container:nth-child(135) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-135;
  animation-duration: 32127ms;
  animation-delay: 34353ms;
}

@keyframes move-frames-135 {
  from {
    transform: translate3d(5vw, 110vh, 0);
  }

  to {
    transform: translate3d(97vw, -121vh, 0);
  }
}

.circle-container:nth-child(135) .circle {
  animation-delay: 799ms;
}

.circle-container:nth-child(136) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-136;
  animation-duration: 29737ms;
  animation-delay: 25429ms;
}

@keyframes move-frames-136 {
  from {
    transform: translate3d(61vw, 108vh, 0);
  }

  to {
    transform: translate3d(97vw, -131vh, 0);
  }
}

.circle-container:nth-child(136) .circle {
  animation-delay: 2671ms;
}

.circle-container:nth-child(137) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-137;
  animation-duration: 34817ms;
  animation-delay: 16326ms;
}

@keyframes move-frames-137 {
  from {
    transform: translate3d(13vw, 109vh, 0);
  }

  to {
    transform: translate3d(63vw, -132vh, 0);
  }
}

.circle-container:nth-child(137) .circle {
  animation-delay: 1927ms;
}

.circle-container:nth-child(138) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-138;
  animation-duration: 36324ms;
  animation-delay: 3871ms;
}

@keyframes move-frames-138 {
  from {
    transform: translate3d(3vw, 109vh, 0);
  }

  to {
    transform: translate3d(5vw, -119vh, 0);
  }
}

.circle-container:nth-child(138) .circle {
  animation-delay: 2589ms;
}

.circle-container:nth-child(139) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-139;
  animation-duration: 33537ms;
  animation-delay: 11929ms;
}

@keyframes move-frames-139 {
  from {
    transform: translate3d(59vw, 109vh, 0);
  }

  to {
    transform: translate3d(9vw, -133vh, 0);
  }
}

.circle-container:nth-child(139) .circle {
  animation-delay: 424ms;
}

.circle-container:nth-child(140) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-140;
  animation-duration: 34662ms;
  animation-delay: 21605ms;
}

@keyframes move-frames-140 {
  from {
    transform: translate3d(84vw, 106vh, 0);
  }

  to {
    transform: translate3d(15vw, -136vh, 0);
  }
}

.circle-container:nth-child(140) .circle {
  animation-delay: 3430ms;
}

.circle-container:nth-child(141) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-141;
  animation-duration: 31241ms;
  animation-delay: 15328ms;
}

@keyframes move-frames-141 {
  from {
    transform: translate3d(30vw, 101vh, 0);
  }

  to {
    transform: translate3d(42vw, -107vh, 0);
  }
}

.circle-container:nth-child(141) .circle {
  animation-delay: 632ms;
}

.circle-container:nth-child(142) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-142;
  animation-duration: 28931ms;
  animation-delay: 10856ms;
}

@keyframes move-frames-142 {
  from {
    transform: translate3d(66vw, 105vh, 0);
  }

  to {
    transform: translate3d(26vw, -128vh, 0);
  }
}

.circle-container:nth-child(142) .circle {
  animation-delay: 507ms;
}

.circle-container:nth-child(143) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-143;
  animation-duration: 35135ms;
  animation-delay: 5486ms;
}

@keyframes move-frames-143 {
  from {
    transform: translate3d(80vw, 105vh, 0);
  }

  to {
    transform: translate3d(28vw, -128vh, 0);
  }
}

.circle-container:nth-child(143) .circle {
  animation-delay: 1790ms;
}

.circle-container:nth-child(144) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-144;
  animation-duration: 34630ms;
  animation-delay: 19793ms;
}

@keyframes move-frames-144 {
  from {
    transform: translate3d(68vw, 108vh, 0);
  }

  to {
    transform: translate3d(66vw, -130vh, 0);
  }
}

.circle-container:nth-child(144) .circle {
  animation-delay: 1756ms;
}

.circle-container:nth-child(145) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-145;
  animation-duration: 29462ms;
  animation-delay: 25517ms;
}

@keyframes move-frames-145 {
  from {
    transform: translate3d(49vw, 104vh, 0);
  }

  to {
    transform: translate3d(73vw, -124vh, 0);
  }
}

.circle-container:nth-child(145) .circle {
  animation-delay: 3803ms;
}

.circle-container:nth-child(146) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-146;
  animation-duration: 35256ms;
  animation-delay: 9570ms;
}

@keyframes move-frames-146 {
  from {
    transform: translate3d(15vw, 106vh, 0);
  }

  to {
    transform: translate3d(29vw, -117vh, 0);
  }
}

.circle-container:nth-child(146) .circle {
  animation-delay: 3970ms;
}

.circle-container:nth-child(147) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-147;
  animation-duration: 33627ms;
  animation-delay: 36109ms;
}

@keyframes move-frames-147 {
  from {
    transform: translate3d(91vw, 110vh, 0);
  }

  to {
    transform: translate3d(67vw, -123vh, 0);
  }
}

.circle-container:nth-child(147) .circle {
  animation-delay: 2714ms;
}

.circle-container:nth-child(148) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-148;
  animation-duration: 34801ms;
  animation-delay: 743ms;
}

@keyframes move-frames-148 {
  from {
    transform: translate3d(82vw, 102vh, 0);
  }

  to {
    transform: translate3d(43vw, -115vh, 0);
  }
}

.circle-container:nth-child(148) .circle {
  animation-delay: 2064ms;
}

.circle-container:nth-child(149) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-149;
  animation-duration: 33669ms;
  animation-delay: 32275ms;
}

@keyframes move-frames-149 {
  from {
    transform: translate3d(45vw, 101vh, 0);
  }

  to {
    transform: translate3d(23vw, -103vh, 0);
  }
}

.circle-container:nth-child(149) .circle {
  animation-delay: 1628ms;
}

.circle-container:nth-child(150) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-150;
  animation-duration: 34795ms;
  animation-delay: 4749ms;
}

@keyframes move-frames-150 {
  from {
    transform: translate3d(14vw, 110vh, 0);
  }

  to {
    transform: translate3d(73vw, -123vh, 0);
  }
}

.circle-container:nth-child(150) .circle {
  animation-delay: 2055ms;
}

.circle-container:nth-child(151) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-151;
  animation-duration: 33885ms;
  animation-delay: 29883ms;
}

@keyframes move-frames-151 {
  from {
    transform: translate3d(4vw, 103vh, 0);
  }

  to {
    transform: translate3d(51vw, -110vh, 0);
  }
}

.circle-container:nth-child(151) .circle {
  animation-delay: 2272ms;
}

.circle-container:nth-child(152) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-152;
  animation-duration: 36908ms;
  animation-delay: 36920ms;
}

@keyframes move-frames-152 {
  from {
    transform: translate3d(30vw, 102vh, 0);
  }

  to {
    transform: translate3d(99vw, -124vh, 0);
  }
}

.circle-container:nth-child(152) .circle {
  animation-delay: 778ms;
}

.circle-container:nth-child(153) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-153;
  animation-duration: 28352ms;
  animation-delay: 7520ms;
}

@keyframes move-frames-153 {
  from {
    transform: translate3d(25vw, 107vh, 0);
  }

  to {
    transform: translate3d(93vw, -111vh, 0);
  }
}

.circle-container:nth-child(153) .circle {
  animation-delay: 720ms;
}

.circle-container:nth-child(154) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-154;
  animation-duration: 36188ms;
  animation-delay: 2989ms;
}

@keyframes move-frames-154 {
  from {
    transform: translate3d(81vw, 102vh, 0);
  }

  to {
    transform: translate3d(20vw, -126vh, 0);
  }
}

.circle-container:nth-child(154) .circle {
  animation-delay: 527ms;
}

.circle-container:nth-child(155) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-155;
  animation-duration: 32707ms;
  animation-delay: 19860ms;
}

@keyframes move-frames-155 {
  from {
    transform: translate3d(99vw, 108vh, 0);
  }

  to {
    transform: translate3d(28vw, -133vh, 0);
  }
}

.circle-container:nth-child(155) .circle {
  animation-delay: 3559ms;
}

.circle-container:nth-child(156) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-156;
  animation-duration: 36634ms;
  animation-delay: 5748ms;
}

@keyframes move-frames-156 {
  from {
    transform: translate3d(16vw, 109vh, 0);
  }

  to {
    transform: translate3d(75vw, -138vh, 0);
  }
}

.circle-container:nth-child(156) .circle {
  animation-delay: 3847ms;
}

.circle-container:nth-child(157) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-157;
  animation-duration: 34146ms;
  animation-delay: 1383ms;
}

@keyframes move-frames-157 {
  from {
    transform: translate3d(14vw, 107vh, 0);
  }

  to {
    transform: translate3d(5vw, -109vh, 0);
  }
}

.circle-container:nth-child(157) .circle {
  animation-delay: 3120ms;
}

.circle-container:nth-child(158) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-158;
  animation-duration: 34661ms;
  animation-delay: 24156ms;
}

@keyframes move-frames-158 {
  from {
    transform: translate3d(13vw, 106vh, 0);
  }

  to {
    transform: translate3d(80vw, -108vh, 0);
  }
}

.circle-container:nth-child(158) .circle {
  animation-delay: 3187ms;
}

.circle-container:nth-child(159) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-159;
  animation-duration: 31555ms;
  animation-delay: 35581ms;
}

@keyframes move-frames-159 {
  from {
    transform: translate3d(4vw, 108vh, 0);
  }

  to {
    transform: translate3d(4vw, -124vh, 0);
  }
}

.circle-container:nth-child(159) .circle {
  animation-delay: 2664ms;
}

.circle-container:nth-child(160) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-160;
  animation-duration: 34063ms;
  animation-delay: 36698ms;
}

@keyframes move-frames-160 {
  from {
    transform: translate3d(19vw, 104vh, 0);
  }

  to {
    transform: translate3d(10vw, -125vh, 0);
  }
}

.circle-container:nth-child(160) .circle {
  animation-delay: 3848ms;
}

.circle-container:nth-child(161) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-161;
  animation-duration: 33296ms;
  animation-delay: 31319ms;
}

@keyframes move-frames-161 {
  from {
    transform: translate3d(9vw, 107vh, 0);
  }

  to {
    transform: translate3d(78vw, -120vh, 0);
  }
}

.circle-container:nth-child(161) .circle {
  animation-delay: 2432ms;
}

.circle-container:nth-child(162) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-162;
  animation-duration: 35812ms;
  animation-delay: 14297ms;
}

@keyframes move-frames-162 {
  from {
    transform: translate3d(74vw, 105vh, 0);
  }

  to {
    transform: translate3d(25vw, -121vh, 0);
  }
}

.circle-container:nth-child(162) .circle {
  animation-delay: 3380ms;
}

.circle-container:nth-child(163) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-163;
  animation-duration: 34820ms;
  animation-delay: 26445ms;
}

@keyframes move-frames-163 {
  from {
    transform: translate3d(10vw, 101vh, 0);
  }

  to {
    transform: translate3d(95vw, -118vh, 0);
  }
}

.circle-container:nth-child(163) .circle {
  animation-delay: 1034ms;
}

.circle-container:nth-child(164) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-164;
  animation-duration: 29794ms;
  animation-delay: 16383ms;
}

@keyframes move-frames-164 {
  from {
    transform: translate3d(27vw, 101vh, 0);
  }

  to {
    transform: translate3d(43vw, -116vh, 0);
  }
}

.circle-container:nth-child(164) .circle {
  animation-delay: 927ms;
}

.circle-container:nth-child(165) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-165;
  animation-duration: 34792ms;
  animation-delay: 30428ms;
}

@keyframes move-frames-165 {
  from {
    transform: translate3d(77vw, 107vh, 0);
  }

  to {
    transform: translate3d(15vw, -121vh, 0);
  }
}

.circle-container:nth-child(165) .circle {
  animation-delay: 2331ms;
}

.circle-container:nth-child(166) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-166;
  animation-duration: 31779ms;
  animation-delay: 8039ms;
}

@keyframes move-frames-166 {
  from {
    transform: translate3d(55vw, 109vh, 0);
  }

  to {
    transform: translate3d(36vw, -133vh, 0);
  }
}

.circle-container:nth-child(166) .circle {
  animation-delay: 1501ms;
}

.circle-container:nth-child(167) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-167;
  animation-duration: 33558ms;
  animation-delay: 18201ms;
}

@keyframes move-frames-167 {
  from {
    transform: translate3d(80vw, 110vh, 0);
  }

  to {
    transform: translate3d(35vw, -113vh, 0);
  }
}

.circle-container:nth-child(167) .circle {
  animation-delay: 3387ms;
}

.circle-container:nth-child(168) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-168;
  animation-duration: 33625ms;
  animation-delay: 21179ms;
}

@keyframes move-frames-168 {
  from {
    transform: translate3d(32vw, 108vh, 0);
  }

  to {
    transform: translate3d(2vw, -130vh, 0);
  }
}

.circle-container:nth-child(168) .circle {
  animation-delay: 1929ms;
}

.circle-container:nth-child(169) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-169;
  animation-duration: 35307ms;
  animation-delay: 29480ms;
}

@keyframes move-frames-169 {
  from {
    transform: translate3d(31vw, 102vh, 0);
  }

  to {
    transform: translate3d(61vw, -130vh, 0);
  }
}

.circle-container:nth-child(169) .circle {
  animation-delay: 1027ms;
}

.circle-container:nth-child(170) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-170;
  animation-duration: 31199ms;
  animation-delay: 28197ms;
}

@keyframes move-frames-170 {
  from {
    transform: translate3d(75vw, 105vh, 0);
  }

  to {
    transform: translate3d(16vw, -127vh, 0);
  }
}

.circle-container:nth-child(170) .circle {
  animation-delay: 3401ms;
}

.circle-container:nth-child(171) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-171;
  animation-duration: 32710ms;
  animation-delay: 25985ms;
}

@keyframes move-frames-171 {
  from {
    transform: translate3d(40vw, 108vh, 0);
  }

  to {
    transform: translate3d(16vw, -129vh, 0);
  }
}

.circle-container:nth-child(171) .circle {
  animation-delay: 3100ms;
}

.circle-container:nth-child(172) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-172;
  animation-duration: 36094ms;
  animation-delay: 22192ms;
}

@keyframes move-frames-172 {
  from {
    transform: translate3d(70vw, 107vh, 0);
  }

  to {
    transform: translate3d(33vw, -133vh, 0);
  }
}

.circle-container:nth-child(172) .circle {
  animation-delay: 3260ms;
}

.circle-container:nth-child(173) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-173;
  animation-duration: 28325ms;
  animation-delay: 14848ms;
}

@keyframes move-frames-173 {
  from {
    transform: translate3d(39vw, 107vh, 0);
  }

  to {
    transform: translate3d(55vw, -137vh, 0);
  }
}

.circle-container:nth-child(173) .circle {
  animation-delay: 1544ms;
}

.circle-container:nth-child(174) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-174;
  animation-duration: 31498ms;
  animation-delay: 34860ms;
}

@keyframes move-frames-174 {
  from {
    transform: translate3d(82vw, 105vh, 0);
  }

  to {
    transform: translate3d(68vw, -110vh, 0);
  }
}

.circle-container:nth-child(174) .circle {
  animation-delay: 1419ms;
}

.circle-container:nth-child(175) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-175;
  animation-duration: 32296ms;
  animation-delay: 12310ms;
}

@keyframes move-frames-175 {
  from {
    transform: translate3d(48vw, 105vh, 0);
  }

  to {
    transform: translate3d(72vw, -123vh, 0);
  }
}

.circle-container:nth-child(175) .circle {
  animation-delay: 89ms;
}

.circle-container:nth-child(176) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-176;
  animation-duration: 31695ms;
  animation-delay: 24667ms;
}

@keyframes move-frames-176 {
  from {
    transform: translate3d(22vw, 109vh, 0);
  }

  to {
    transform: translate3d(4vw, -126vh, 0);
  }
}

.circle-container:nth-child(176) .circle {
  animation-delay: 3187ms;
}

.circle-container:nth-child(177) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-177;
  animation-duration: 31664ms;
  animation-delay: 18211ms;
}

@keyframes move-frames-177 {
  from {
    transform: translate3d(49vw, 101vh, 0);
  }

  to {
    transform: translate3d(29vw, -125vh, 0);
  }
}

.circle-container:nth-child(177) .circle {
  animation-delay: 2819ms;
}

.circle-container:nth-child(178) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-178;
  animation-duration: 28392ms;
  animation-delay: 34567ms;
}

@keyframes move-frames-178 {
  from {
    transform: translate3d(51vw, 110vh, 0);
  }

  to {
    transform: translate3d(100vw, -131vh, 0);
  }
}

.circle-container:nth-child(178) .circle {
  animation-delay: 2722ms;
}

.circle-container:nth-child(179) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-179;
  animation-duration: 30963ms;
  animation-delay: 24078ms;
}

@keyframes move-frames-179 {
  from {
    transform: translate3d(52vw, 108vh, 0);
  }

  to {
    transform: translate3d(41vw, -136vh, 0);
  }
}

.circle-container:nth-child(179) .circle {
  animation-delay: 1139ms;
}

.circle-container:nth-child(180) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-180;
  animation-duration: 33541ms;
  animation-delay: 17296ms;
}

@keyframes move-frames-180 {
  from {
    transform: translate3d(57vw, 102vh, 0);
  }

  to {
    transform: translate3d(58vw, -132vh, 0);
  }
}

.circle-container:nth-child(180) .circle {
  animation-delay: 2123ms;
}

.circle-container:nth-child(181) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-181;
  animation-duration: 32098ms;
  animation-delay: 24109ms;
}

@keyframes move-frames-181 {
  from {
    transform: translate3d(16vw, 107vh, 0);
  }

  to {
    transform: translate3d(71vw, -116vh, 0);
  }
}

.circle-container:nth-child(181) .circle {
  animation-delay: 215ms;
}

.circle-container:nth-child(182) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-182;
  animation-duration: 33521ms;
  animation-delay: 20413ms;
}

@keyframes move-frames-182 {
  from {
    transform: translate3d(1vw, 106vh, 0);
  }

  to {
    transform: translate3d(17vw, -136vh, 0);
  }
}

.circle-container:nth-child(182) .circle {
  animation-delay: 2956ms;
}

.circle-container:nth-child(183) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-183;
  animation-duration: 35438ms;
  animation-delay: 29444ms;
}

@keyframes move-frames-183 {
  from {
    transform: translate3d(20vw, 102vh, 0);
  }

  to {
    transform: translate3d(35vw, -126vh, 0);
  }
}

.circle-container:nth-child(183) .circle {
  animation-delay: 3321ms;
}

.circle-container:nth-child(184) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-184;
  animation-duration: 34660ms;
  animation-delay: 22744ms;
}

@keyframes move-frames-184 {
  from {
    transform: translate3d(12vw, 103vh, 0);
  }

  to {
    transform: translate3d(10vw, -121vh, 0);
  }
}

.circle-container:nth-child(184) .circle {
  animation-delay: 3626ms;
}

.circle-container:nth-child(185) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-185;
  animation-duration: 33549ms;
  animation-delay: 17214ms;
}

@keyframes move-frames-185 {
  from {
    transform: translate3d(3vw, 109vh, 0);
  }

  to {
    transform: translate3d(97vw, -111vh, 0);
  }
}

.circle-container:nth-child(185) .circle {
  animation-delay: 3664ms;
}

.circle-container:nth-child(186) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-186;
  animation-duration: 30535ms;
  animation-delay: 32724ms;
}

@keyframes move-frames-186 {
  from {
    transform: translate3d(78vw, 103vh, 0);
  }

  to {
    transform: translate3d(75vw, -115vh, 0);
  }
}

.circle-container:nth-child(186) .circle {
  animation-delay: 3761ms;
}

.circle-container:nth-child(187) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-187;
  animation-duration: 35438ms;
  animation-delay: 18335ms;
}

@keyframes move-frames-187 {
  from {
    transform: translate3d(45vw, 104vh, 0);
  }

  to {
    transform: translate3d(99vw, -120vh, 0);
  }
}

.circle-container:nth-child(187) .circle {
  animation-delay: 477ms;
}

.circle-container:nth-child(188) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-188;
  animation-duration: 33244ms;
  animation-delay: 6241ms;
}

@keyframes move-frames-188 {
  from {
    transform: translate3d(17vw, 107vh, 0);
  }

  to {
    transform: translate3d(22vw, -126vh, 0);
  }
}

.circle-container:nth-child(188) .circle {
  animation-delay: 899ms;
}

.circle-container:nth-child(189) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-189;
  animation-duration: 34146ms;
  animation-delay: 22185ms;
}

@keyframes move-frames-189 {
  from {
    transform: translate3d(96vw, 105vh, 0);
  }

  to {
    transform: translate3d(54vw, -129vh, 0);
  }
}

.circle-container:nth-child(189) .circle {
  animation-delay: 1636ms;
}

.circle-container:nth-child(190) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-190;
  animation-duration: 31554ms;
  animation-delay: 3633ms;
}

@keyframes move-frames-190 {
  from {
    transform: translate3d(58vw, 105vh, 0);
  }

  to {
    transform: translate3d(31vw, -133vh, 0);
  }
}

.circle-container:nth-child(190) .circle {
  animation-delay: 1078ms;
}

.circle-container:nth-child(191) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-191;
  animation-duration: 28930ms;
  animation-delay: 26467ms;
}

@keyframes move-frames-191 {
  from {
    transform: translate3d(79vw, 101vh, 0);
  }

  to {
    transform: translate3d(62vw, -108vh, 0);
  }
}

.circle-container:nth-child(191) .circle {
  animation-delay: 3548ms;
}

.circle-container:nth-child(192) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-192;
  animation-duration: 28483ms;
  animation-delay: 34660ms;
}

@keyframes move-frames-192 {
  from {
    transform: translate3d(85vw, 103vh, 0);
  }

  to {
    transform: translate3d(54vw, -121vh, 0);
  }
}

.circle-container:nth-child(192) .circle {
  animation-delay: 440ms;
}

.circle-container:nth-child(193) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-193;
  animation-duration: 32543ms;
  animation-delay: 10492ms;
}

@keyframes move-frames-193 {
  from {
    transform: translate3d(59vw, 104vh, 0);
  }

  to {
    transform: translate3d(69vw, -131vh, 0);
  }
}

.circle-container:nth-child(193) .circle {
  animation-delay: 829ms;
}

.circle-container:nth-child(194) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-194;
  animation-duration: 30645ms;
  animation-delay: 12380ms;
}

@keyframes move-frames-194 {
  from {
    transform: translate3d(12vw, 110vh, 0);
  }

  to {
    transform: translate3d(45vw, -127vh, 0);
  }
}

.circle-container:nth-child(194) .circle {
  animation-delay: 1113ms;
}

.circle-container:nth-child(195) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-195;
  animation-duration: 34270ms;
  animation-delay: 15876ms;
}

@keyframes move-frames-195 {
  from {
    transform: translate3d(50vw, 101vh, 0);
  }

  to {
    transform: translate3d(87vw, -118vh, 0);
  }
}

.circle-container:nth-child(195) .circle {
  animation-delay: 1624ms;
}

.circle-container:nth-child(196) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-196;
  animation-duration: 29392ms;
  animation-delay: 25816ms;
}

@keyframes move-frames-196 {
  from {
    transform: translate3d(42vw, 102vh, 0);
  }

  to {
    transform: translate3d(63vw, -107vh, 0);
  }
}

.circle-container:nth-child(196) .circle {
  animation-delay: 1477ms;
}

.circle-container:nth-child(197) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-197;
  animation-duration: 36800ms;
  animation-delay: 5377ms;
}

@keyframes move-frames-197 {
  from {
    transform: translate3d(79vw, 101vh, 0);
  }

  to {
    transform: translate3d(82vw, -130vh, 0);
  }
}

.circle-container:nth-child(197) .circle {
  animation-delay: 3276ms;
}

.circle-container:nth-child(198) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-198;
  animation-duration: 28760ms;
  animation-delay: 21968ms;
}

@keyframes move-frames-198 {
  from {
    transform: translate3d(16vw, 106vh, 0);
  }

  to {
    transform: translate3d(5vw, -126vh, 0);
  }
}

.circle-container:nth-child(198) .circle {
  animation-delay: 2084ms;
}

.circle-container:nth-child(199) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-199;
  animation-duration: 33994ms;
  animation-delay: 7886ms;
}

@keyframes move-frames-199 {
  from {
    transform: translate3d(72vw, 106vh, 0);
  }

  to {
    transform: translate3d(25vw, -123vh, 0);
  }
}

.circle-container:nth-child(199) .circle {
  animation-delay: 367ms;
}

.circle-container:nth-child(200) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-200;
  animation-duration: 32701ms;
  animation-delay: 18293ms;
}

@keyframes move-frames-200 {
  from {
    transform: translate3d(61vw, 103vh, 0);
  }

  to {
    transform: translate3d(24vw, -110vh, 0);
  }
}

.circle-container:nth-child(200) .circle {
  animation-delay: 3411ms;
}

.page-loader {
  position: sticky;
  position: -webkit-sticky;
  width: 100%;
  height: 100vh;
  background: rgb(255, 198, 38);
  background: radial-gradient(circle, rgba(255, 198, 38, 1) 0%, rgba(116, 228, 167, 1) 46%, rgba(146, 230, 255, 1) 100%);
  z-index: 100000;
  display: block;
  top: 0;
  bottom: 0;

}

.loadingText2:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;
}

.page-loader .loadLogo {
  font-family: 'Luckiest Guy', cursive;
  background: -webkit-linear-gradient(#8c31c4, #a759d7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  top: 30%;
  position: relative;
  letter-spacing: 2px;
  font-size: 40px;
  margin-top: 2%;
}

.loadLogo img {
  width: 250px;
  height: 250px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  animation: zoominoutsinglefeatured 3s infinite;
  border-radius: 100%;
  border: 5px rgb(255, 255, 255) solid;

}

@media screen and (max-width: 499px) {

  .discord4{
    display: none;
  }

  .rmFish {
    width: 250px;
    height: 250px;
    animation: fishSwim 40s infinite;
    
  }

  .rmFish4 {
    width: 300px;
    height: 300px;
    animation: fishSwim 30s infinite;
    margin-top: -400px;

  }

  .rmFish2 {
    width: 250px;
    height: 250px;
    animation: fishSwim2 35s infinite;
    margin-top: -300px;
  }

  .rmFish3 {
    width: 270px;
    height: 270px;
    animation: fishSwim2 25s infinite;
    margin-top: -350px;
  }

  @keyframes fishSwim {
    0% {
      transform: translateX(-60vw);
      opacity: 1;
    }

    100% {
      transform: translateX(120vw);
      opacity: 1;

    }

  }

  @keyframes fishSwim2 {
    0% {
      transform: translateX(120vw) scaleX(-1);
      opacity: 1;

    }

    100% {
      transform: translateX(-80vw) scaleX(-1);
      opacity: 1;

    }

  }

  .rmNewMain {
    flex-flow: column nowrap;
  }

  .rmNew2 {
    flex-flow: column nowrap;
    justify-content: center;
    background-color: #01345e91;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    padding: 20px;
    margin-bottom: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .rmQ {
    text-align: left;
    color: #69b0c0;
    font-size: 27px;
  }

  .rmPNew {
    color: white;
    font-size: 18px;
  }

  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: -500px;
    z-index: 10000;
  }

  .homeImages1 {
    animation: Slide_Down1 12s ease infinite;
    z-index: 10000;

  }

  .homeImages2 {
    animation: Slide_Down2 15s ease infinite;
    z-index: 10000;

  }

  @keyframes Slide_Down1 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }

  @keyframes Slide_Down2 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }


  .rightPic {
    width: 300px;
    height: 300px;
    z-index: 1000000;
  }

  .connect2 {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .rmH2 {
    display: none;
  }

  .discordF2 {
    display: none;
  }

  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .rmP2 {
    display: none;
  }

  .connect {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('https://fishpricsassets.pages.dev/assets/aboutBg.jpg')no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('assets/rmBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Opensea {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('https://fishpricsassets.pages.dev/assets/aboutBg.jpg')no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 50%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('assets/mintBg.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    
  }


  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 10%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
    background-color: black;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;

  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;

  }

  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 60px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    background: -webkit-linear-gradient(#a7eca7, #048604);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    letter-spacing: 5px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ee5bf6;
    margin-bottom: 1%;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 70px;
    color: white;

    text-align: center;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }

  .mintH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .storyCon {
    color: white;
    font-size: 20px;
    text-align: center;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
  }

  .storyConOs {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
    margin-bottom: 550px;
  }

  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }


  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyCon2 {
    font-size: 22px;

  }

  .storyCon2Rm {
    font-size: 20px;

  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 70px;
    text-align: center;

  }

  .intro img {
    width: 60%;
    z-index: 100000;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: column nowrap;
    display: flex;
    justify-content: center;
    border: #4086e2 10px solid;

    border-radius: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 14px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    display: flex;
  
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
    margin-top: 90px;
   }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1000;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connectMobile {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
     margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2Mobile {
    z-index: 1000;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    justify-content: center;
   }

  .connectMobile div {
    margin-left: 10px;
    margin-right: 10px;

    color: #2aacff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }

  .connectMobile div:hover{
    color: white;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }

  .btnfos-0-3-1 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  btnfos-0-3-1:active {
    background-color: #7a1883;

  }

  .btnfos-0-3-1:focus {
    background-color: #7a1883;
  }



  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  .btnfos-0-3:active {
    background-color: #7a1883;

  }

  .btnfos-0-3:focus {
    background-color: #7a1883;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: white;
    font-size: 85px;

    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;

    font-size: 30px;
  }

  .iconsMob {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-top: -50px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  
  }


  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .middleH {
    width: 330px;
    height: 231px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
     z-index: 100;
    margin-top: 25px;
    }

  .middleM {
    width: 350px;
    height: 121px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    z-index: 100;
  }

  .middleS {
    width: 320px;
    height: 110px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .middleO {
    width: 400px;
    height: 179px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .middleR {
    width: 320px;
    height: 110px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }


  .wallet2 {
    z-index: 1000;
    background-color: #2aacff;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 200px;
    transition: transform .2s;
    border: none;

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .artSub {
    color: white;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 3%;

  }

  .artSub:hover {
    transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    background: -webkit-linear-gradient(#2ac7ff, #8729bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 18px;
    padding-bottom: 10px;
   }

  .footer {}

  .logoF {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
    border-radius: 100%;
    border: 2px solid white;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 100px;
    height: 86px;
  }

  .sop {
    width: 130px;
    height: 59px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
  }

  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 25px;

    cursor: pointer;
    z-index: 10000;

  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 270px;
    height: 270px;
    border-radius: 20px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }

  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }


  .rmBoxMain2 {
    margin-top: 30%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    }

    .videoBg {
      position: absolute;
      top: 20%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-10%);
      transform: translateX(-50%) translateY(-10%);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      overflow: hidden;
    }

  .bgImg {
    background: url('https://smassets.pages.dev/assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }


  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }

  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1.3, 1.3);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1.3, 1.3);
    }
  }

  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }

  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }

  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }

  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }

  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }

  .teamCon img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }

  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }

  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .twitterTeamDiv {
    background-color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;

  }

  .twitterTeamDiv:hover {
    transform: scale(1.2);
    filter: none;
  }

  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: transform 1s;
    filter: grayscale(100%);
    transition: 0.5s ease;
  }

  .art:hover {
    transform: scale(1.1);
    filter: none;
  }

  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }

  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }

  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }

  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }

  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }

  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }

  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }

  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }

  .lightrope li:last-child:after {
    content: none;
  }

  .lightrope li:first-child {
    margin-left: -40px;
  }

  @-webkit-keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @-webkit-keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @-webkit-keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  @keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }

  .footerIcons img {
    width: 45px;
    height: 45px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .footerIcons img:hover {
    transform: scale(1.3);
    filter: none;
  }

  /* Start https://www.cursors-4u.com */

  /* End https://www.cursors-4u.com */

  /* Basic Reset */

  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

   @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .stickyGhost {
    width: 250px;
    height: 367px;
    position: sticky;
    position: -webkit-sticky;
    right: 100%;
    margin-top: -40%;
    bottom: 0;
    transform: rotate(65deg);
  }

  .animation-wrapper {
    background: #ace5ee;
    left: 0;
    position: absolute;
    right: 0;
  }

  .water {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .water ul.waves {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .water ul.waves li {
    background-repeat: repeat-x;
  }

  .water ul.waves li.wave-one {
    animation: wave 8.7s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    bottom: 0px;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    background-image: url('https://smassets.pages.dev/assets/wave1.png');
    background-size: contain;
  }

  .water ul.waves li.wave-two {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 10s linear infinite;
    bottom: 0px;
    height: 84px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 9;
    background-image: url(https://i.postimg.cc/P5hv05rh/wave2.png);
    background-size: contain;
  }

  .water ul.waves li.wave-three {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 190px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 8;
    background-image: url(https://i.postimg.cc/63Dyc91k/wave3.png);
    background-size: contain;
  }

  .water ul.waves li.wave-four {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 205px;
    left: 0;
    position: absolute;
    right: 0;
    background-image: url(https://i.postimg.cc/1tg8DgM0/wave4.png);
    background-size: contain;
  }

  .boat {
    animation: boat 3s linear infinite;
    background-repeat: no-repeat;
    bottom: 175px;
    height: 145px;
    left: 50%;
    position: absolute;
    width: 350px;
    height: 389px;
    background-image: url('https://smassets.pages.dev/assets/boat.png');
    background-size: contain;
    -webkit-transform: scaleX(-1);
  }

  .cloud {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 280px;
    height: 165px;
    left: 110px;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .cloud2 {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 450px;
    height: 165px;
    left: 0;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .dolphin {
    animation: fish 15s linear infinite;
    background-repeat: no-repeat;
    bottom: 45px;
    height: 80px;
    left: 20%;
    position: absolute;
    width: 124px;
    z-index: 9;
  }





  /*Animation Keyframe Section*/

  @keyframes wave {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 1920px 0;
    }
  }

  @keyframes boat {
    0% {
      transform: rotate(0);
    }

    50% {
      transform: rotate(-3deg);
    }

    100% {
      transform: rotate(0);
    }
  }

  @keyframes cloud {
    0% {
      left: -30%;
    }

    100% {
      left: 100%;
    }
  }

  .transition {
    width: 100%;
  }

  .openseaButton {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #58d4ff;
    color: #ffffff;
    border: none;
    font-size: 50px;
    padding: 10px;
    border-radius: 50px;
    box-shadow: 0 5px #409cbb;
    transition: 0.5s ease;
    padding-left: 15px;
    padding-right: 15px;
    background-image: linear-gradient(to right,
        #ff7d84,
        #ff7d84 16.65%,
        #ff733f 16.65%,
        #ff733f 33.3%,
        #39b6ec 33.3%,
        #39b6ec 49.95%,
        #ecd435 49.95%,
        #ecd435 66.6%,
        #bc3ceb 66.6%,
        #bc3ceb 83.25%,
        #fe8abe 83.25%,
        #fe8abe 100%,
        #E7484F 100%);
    animation: slidebg 2s linear infinite;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .openseaButton:hover {

    color: white;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .discord4{
    display: none;
  }
  .rmFish {
    width: 250px;
    height: 250px;
    animation: fishSwim 40s infinite;
    
  }

  .rmFish4 {
    width: 300px;
    height: 300px;
    animation: fishSwim 30s infinite;
    margin-top: -400px;

  }

  .rmFish2 {
    width: 250px;
    height: 250px;
    animation: fishSwim2 35s infinite;
    margin-top: -300px;
  }

  .rmFish3 {
    width: 270px;
    height: 270px;
    animation: fishSwim2 25s infinite;
    margin-top: -350px;
  }

  @keyframes fishSwim {
    0% {
      transform: translateX(-30vw);
      opacity: 1;
    }

    100% {
      transform: translateX(100vw);
      opacity: 1;

    }

  }

  @keyframes fishSwim2 {
    0% {
      transform: translateX(100vw) scaleX(-1);
      opacity: 1;

    }

    100% {
      transform: translateX(-50vw) scaleX(-1);
      opacity: 1;

    }

  }

  .rmNewMain {
    flex-flow: column nowrap;
  }

  .rmNew2 {
    flex-flow: column nowrap;
    justify-content: center;
    background-color: #01345e91;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    padding: 20px;
    margin-bottom: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .rmQ {
    text-align: left;
    color: #69b0c0;
    font-size: 27px;
  }

  .rmPNew {
    color: white;
    font-size: 18px;
  }

  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: -500px;
    z-index: 10000;
  }

  .homeImages1 {
    animation: Slide_Down1 12s ease infinite;
    z-index: 10000;

  }

  .homeImages2 {
    animation: Slide_Down2 15s ease infinite;
    z-index: 10000;

  }

  @keyframes Slide_Down1 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }

  @keyframes Slide_Down2 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }


  .rightPic {
    width: 300px;
    height: 300px;
    z-index: 1000000;
  }

  .connect2 {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .rmH2 {
    display: none;
  }

  .discordF2 {
    display: none;
  }

  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .rmP2 {
    display: none;
  }

  .connect {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('https://fishpricsassets.pages.dev/assets/aboutBg.jpg')no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('assets/rmBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Opensea {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('https://fishpricsassets.pages.dev/assets/aboutBg.jpg')no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 40%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('assets/mintBg.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    
  }


  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 10%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
    background-color: black;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;

  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;

  }

  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 60px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    background: -webkit-linear-gradient(#a7eca7, #048604);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    letter-spacing: 5px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ee5bf6;
    margin-bottom: 1%;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 70px;
    color: white;

    text-align: center;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }

  .mintH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .storyCon {
    color: white;
    font-size: 20px;
    text-align: center;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
  }

  .storyConOs {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
    margin-bottom: 550px;
  }

  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }


  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyCon2 {
    font-size: 22px;

  }

  .storyCon2Rm {
    font-size: 20px;

  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 70px;
    text-align: center;

  }

  .intro img {
    width: 60%;
    z-index: 100000;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: column nowrap;
    display: flex;
    justify-content: center;
    border: #4086e2 10px solid;

    border-radius: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 14px;
    padding-top: 5px;
  }
  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    display: flex;
  
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    flex-flow: column nowrap;
    margin-top: 90px;
   }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1000;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connectMobile {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
     margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2Mobile {
    z-index: 1000;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    justify-content: center;
   }

  .connectMobile div {
    margin-left: 10px;
    margin-right: 10px;

    color: #2aacff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }

  .connectMobile div:hover{
    color: white;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }

  .btnfos-0-3-1 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  btnfos-0-3-1:active {
    background-color: #7a1883;

  }

  .btnfos-0-3-1:focus {
    background-color: #7a1883;
  }



  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  .btnfos-0-3:active {
    background-color: #7a1883;

  }

  .btnfos-0-3:focus {
    background-color: #7a1883;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: white;
    font-size: 85px;

    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;

    font-size: 30px;
  }

  .iconsMob {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-top: -50px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  
  }


  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .middleH {
    width: 400px;
    height: 280px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
     z-index: 100;
    margin-top: 25px;
    }

  .middleM {
    width: 380px;
    height: 131px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    z-index: 100;
  }

  .middleS {
    width: 350px;
    height: 121px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .middleO {
    width: 400px;
    height: 179px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .middleR {
    width: 350px;
    height: 121px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }


  .wallet2 {
    z-index: 1000;
    background-color: #2aacff;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 200px;
    transition: transform .2s;
    border: none;

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .artSub {
    color: white;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 3%;

  }

  .artSub:hover {
    transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    background: -webkit-linear-gradient(#2ac7ff, #8729bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 20px;
    padding-bottom: 10px;
   }

  .footer {}

  .logoF {
    width: 23%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
    border-radius: 100%;
    border: 2px solid white;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 100px;
    height: 86px;
  }

  .sop {
    width: 130px;
    height: 59px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
  }

  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 25px;

    cursor: pointer;
    z-index: 10000;

  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 370px;
    height: 370px;
    border-radius: 20px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }

  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }


  .rmBoxMain2 {
    margin-top: 30%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    }

    .videoBg {
      position: absolute;
      top: 20%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-10%);
      transform: translateX(-50%) translateY(-10%);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      overflow: hidden;
    }

  .bgImg {
    background: url('https://smassets.pages.dev/assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }


  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }

  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1.3, 1.3);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1.3, 1.3);
    }
  }

  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }

  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }

  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }

  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }

  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }

  .teamCon img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }

  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }

  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .twitterTeamDiv {
    background-color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;

  }

  .twitterTeamDiv:hover {
    transform: scale(1.2);
    filter: none;
  }

  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: transform 1s;
    filter: grayscale(100%);
    transition: 0.5s ease;
  }

  .art:hover {
    transform: scale(1.1);
    filter: none;
  }

  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }

  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }

  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }

  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }

  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }

  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }

  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }

  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }

  .lightrope li:last-child:after {
    content: none;
  }

  .lightrope li:first-child {
    margin-left: -40px;
  }

  @-webkit-keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @-webkit-keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @-webkit-keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  @keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }

  .footerIcons img {
    width: 45px;
    height: 45px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .footerIcons img:hover {
    transform: scale(1.3);
    filter: none;
  }

  /* Start https://www.cursors-4u.com */

  /* End https://www.cursors-4u.com */

  /* Basic Reset */

  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

   @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .stickyGhost {
    width: 250px;
    height: 367px;
    position: sticky;
    position: -webkit-sticky;
    right: 100%;
    margin-top: -40%;
    bottom: 0;
    transform: rotate(65deg);
  }

  .animation-wrapper {
    background: #ace5ee;
    left: 0;
    position: absolute;
    right: 0;
  }

  .water {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .water ul.waves {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .water ul.waves li {
    background-repeat: repeat-x;
  }

  .water ul.waves li.wave-one {
    animation: wave 8.7s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    bottom: 0px;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    background-image: url('https://smassets.pages.dev/assets/wave1.png');
    background-size: contain;
  }

  .water ul.waves li.wave-two {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 10s linear infinite;
    bottom: 0px;
    height: 84px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 9;
    background-image: url(https://i.postimg.cc/P5hv05rh/wave2.png);
    background-size: contain;
  }

  .water ul.waves li.wave-three {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 190px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 8;
    background-image: url(https://i.postimg.cc/63Dyc91k/wave3.png);
    background-size: contain;
  }

  .water ul.waves li.wave-four {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 205px;
    left: 0;
    position: absolute;
    right: 0;
    background-image: url(https://i.postimg.cc/1tg8DgM0/wave4.png);
    background-size: contain;
  }

  .boat {
    animation: boat 3s linear infinite;
    background-repeat: no-repeat;
    bottom: 175px;
    height: 145px;
    left: 50%;
    position: absolute;
    width: 350px;
    height: 389px;
    background-image: url('https://smassets.pages.dev/assets/boat.png');
    background-size: contain;
    -webkit-transform: scaleX(-1);
  }

  .cloud {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 280px;
    height: 165px;
    left: 110px;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .cloud2 {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 450px;
    height: 165px;
    left: 0;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .dolphin {
    animation: fish 15s linear infinite;
    background-repeat: no-repeat;
    bottom: 45px;
    height: 80px;
    left: 20%;
    position: absolute;
    width: 124px;
    z-index: 9;
  }





  /*Animation Keyframe Section*/

  @keyframes wave {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 1920px 0;
    }
  }

  @keyframes boat {
    0% {
      transform: rotate(0);
    }

    50% {
      transform: rotate(-3deg);
    }

    100% {
      transform: rotate(0);
    }
  }

  @keyframes cloud {
    0% {
      left: -30%;
    }

    100% {
      left: 100%;
    }
  }

  .transition {
    width: 100%;
  }

  .openseaButton {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #58d4ff;
    color: #ffffff;
    border: none;
    font-size: 50px;
    padding: 10px;
    border-radius: 50px;
    box-shadow: 0 5px #409cbb;
    transition: 0.5s ease;
    padding-left: 15px;
    padding-right: 15px;
    background-image: linear-gradient(to right,
        #ff7d84,
        #ff7d84 16.65%,
        #ff733f 16.65%,
        #ff733f 33.3%,
        #39b6ec 33.3%,
        #39b6ec 49.95%,
        #ecd435 49.95%,
        #ecd435 66.6%,
        #bc3ceb 66.6%,
        #bc3ceb 83.25%,
        #fe8abe 83.25%,
        #fe8abe 100%,
        #E7484F 100%);
    animation: slidebg 2s linear infinite;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .openseaButton:hover {

    color: white;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .discord4{
    display: none;
  }
  .rmFish {
    width: 400px;
    height: 400px;
    animation: fishSwim 40s infinite;
    
  }

  .rmFish4 {
    width: 350px;
    height: 350px;
    animation: fishSwim 30s infinite;
    margin-top: -400px;

  }

  .rmFish2 {
    width: 400px;
    height: 400px;
    animation: fishSwim2 35s infinite;
    margin-top: -300px;
  }

  .rmFish3 {
    width: 400px;
    height: 400px;
    animation: fishSwim2 25s infinite;
    margin-top: -350px;
  }

  @keyframes fishSwim {
    0% {
      transform: translateX(-30vw);
      opacity: 1;
    }

    100% {
      transform: translateX(100vw);
      opacity: 1;

    }

  }

  @keyframes fishSwim2 {
    0% {
      transform: translateX(100vw) scaleX(-1);
      opacity: 1;

    }

    100% {
      transform: translateX(-50vw) scaleX(-1);
      opacity: 1;

    }

  }

  .rmNewMain {
    flex-flow: column nowrap;
  }

  .rmNew2 {
    flex-flow: column nowrap;
    justify-content: center;
    background-color: #01345e91;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    padding: 20px;
    margin-bottom: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .rmQ {
    text-align: left;
    color: #69b0c0;
    font-size: 30px;
  }

  .rmPNew {
    color: white;
    font-size: 20px;
  }

  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: -500px;
    z-index: 10000;
  }

  .homeImages1 {
    animation: Slide_Down1 12s ease infinite;
    z-index: 10000;

  }

  .homeImages2 {
    animation: Slide_Down2 15s ease infinite;
    z-index: 10000;

  }

  @keyframes Slide_Down1 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }

  @keyframes Slide_Down2 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }


  .rightPic {
    width: 300px;
    height: 300px;
    z-index: 1000000;
  }

  .connect2 {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .rmH2 {
    display: none;
  }

  .discordF2 {
    display: none;
  }

  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .rmP2 {
    display: none;
  }

  .connect {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('https://fishpricsassets.pages.dev/assets/aboutBg.jpg')no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('assets/rmBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Opensea {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('https://fishpricsassets.pages.dev/assets/aboutBg.jpg')no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('assets/mintBg.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    
  }


  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
    background-color: black;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;

  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;

  }

  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 60px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    background: -webkit-linear-gradient(#a7eca7, #048604);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    letter-spacing: 5px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ee5bf6;
    margin-bottom: 1%;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 70px;
    color: white;

    text-align: center;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }

  .mintH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .storyCon {
    color: white;
    font-size: 20px;
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
  }

  .storyConOs {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
    margin-bottom: 550px;
  }

  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }


  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyCon2 {
    font-size: 25px;

  }

  .storyCon2Rm {
    font-size: 20px;

  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 70px;
    text-align: center;

  }

  .intro img {
    width: 60%;
    z-index: 100000;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: column nowrap;
    display: flex;
    justify-content: center;
    border: #4086e2 10px solid;

    border-radius: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 14px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    display: flex;
  
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    flex-flow: column nowrap;
    margin-top: 90px;
   }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1000;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connectMobile {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
     margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2Mobile {
    z-index: 1000;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    justify-content: center;
   }

  .connectMobile div {
    margin-left: 10px;
    margin-right: 10px;

    color: #2aacff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }

  .connectMobile div:hover{
    color: white;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }

  .btnfos-0-3-1 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  btnfos-0-3-1:active {
    background-color: #7a1883;

  }

  .btnfos-0-3-1:focus {
    background-color: #7a1883;
  }



  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  .btnfos-0-3:active {
    background-color: #7a1883;

  }

  .btnfos-0-3:focus {
    background-color: #7a1883;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: white;
    font-size: 85px;

    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;

    font-size: 30px;
  }

  .iconsMob {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-top: -50px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  
  }


  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .middleH {
    width: 400px;
    height: 280px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
     z-index: 100;
    margin-top: 25px;
    }

  .middleM {
    width: 500px;
    height: 172px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    z-index: 100;
  }

  .middleS {
    width: 400px;
    height: 138px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .middleO {
    width: 400px;
    height: 179px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .middleR {
    width: 500px;
    height: 194px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }


  .wallet2 {
    z-index: 1000;
    background-color: #2aacff;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 200px;
    transition: transform .2s;
    border: none;

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .artSub {
    color: white;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 3%;

  }

  .artSub:hover {
    transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    background: -webkit-linear-gradient(#2ac7ff, #8729bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 20px;
    padding-bottom: 10px;
   }

  .footer {}

  .logoF {
    width: 15%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
    border-radius: 100%;
    border: 2px solid white;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 100px;
    height: 86px;
  }

  .sop {
    width: 130px;
    height: 59px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
  }

  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 25px;

    cursor: pointer;
    z-index: 10000;

  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 370px;
    height: 370px;
    border-radius: 20px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }

  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }


  .rmBoxMain2 {
    margin-top: 30%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    }

    .videoBg {
      position: absolute;
      top: 20%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-10%);
      transform: translateX(-50%) translateY(-10%);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      overflow: hidden;
    }

  .bgImg {
    background: url('https://smassets.pages.dev/assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }


  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }

  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1.3, 1.3);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1.3, 1.3);
    }
  }

  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }

  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }

  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }

  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }

  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }

  .teamCon img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }

  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }

  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .twitterTeamDiv {
    background-color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;

  }

  .twitterTeamDiv:hover {
    transform: scale(1.2);
    filter: none;
  }

  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: transform 1s;
    filter: grayscale(100%);
    transition: 0.5s ease;
  }

  .art:hover {
    transform: scale(1.1);
    filter: none;
  }

  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }

  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }

  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }

  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }

  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }

  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }

  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }

  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }

  .lightrope li:last-child:after {
    content: none;
  }

  .lightrope li:first-child {
    margin-left: -40px;
  }

  @-webkit-keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @-webkit-keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @-webkit-keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  @keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }

  .footerIcons img {
    width: 45px;
    height: 45px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .footerIcons img:hover {
    transform: scale(1.3);
    filter: none;
  }

  /* Start https://www.cursors-4u.com */

  /* End https://www.cursors-4u.com */

  /* Basic Reset */

  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

   @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .stickyGhost {
    width: 250px;
    height: 367px;
    position: sticky;
    position: -webkit-sticky;
    right: 100%;
    margin-top: -40%;
    bottom: 0;
    transform: rotate(65deg);
  }

  .animation-wrapper {
    background: #ace5ee;
    left: 0;
    position: absolute;
    right: 0;
  }

  .water {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .water ul.waves {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .water ul.waves li {
    background-repeat: repeat-x;
  }

  .water ul.waves li.wave-one {
    animation: wave 8.7s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    bottom: 0px;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    background-image: url('https://smassets.pages.dev/assets/wave1.png');
    background-size: contain;
  }

  .water ul.waves li.wave-two {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 10s linear infinite;
    bottom: 0px;
    height: 84px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 9;
    background-image: url(https://i.postimg.cc/P5hv05rh/wave2.png);
    background-size: contain;
  }

  .water ul.waves li.wave-three {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 190px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 8;
    background-image: url(https://i.postimg.cc/63Dyc91k/wave3.png);
    background-size: contain;
  }

  .water ul.waves li.wave-four {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 205px;
    left: 0;
    position: absolute;
    right: 0;
    background-image: url(https://i.postimg.cc/1tg8DgM0/wave4.png);
    background-size: contain;
  }

  .boat {
    animation: boat 3s linear infinite;
    background-repeat: no-repeat;
    bottom: 175px;
    height: 145px;
    left: 50%;
    position: absolute;
    width: 350px;
    height: 389px;
    background-image: url('https://smassets.pages.dev/assets/boat.png');
    background-size: contain;
    -webkit-transform: scaleX(-1);
  }

  .cloud {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 280px;
    height: 165px;
    left: 110px;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .cloud2 {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 450px;
    height: 165px;
    left: 0;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .dolphin {
    animation: fish 15s linear infinite;
    background-repeat: no-repeat;
    bottom: 45px;
    height: 80px;
    left: 20%;
    position: absolute;
    width: 124px;
    z-index: 9;
  }





  /*Animation Keyframe Section*/

  @keyframes wave {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 1920px 0;
    }
  }

  @keyframes boat {
    0% {
      transform: rotate(0);
    }

    50% {
      transform: rotate(-3deg);
    }

    100% {
      transform: rotate(0);
    }
  }

  @keyframes cloud {
    0% {
      left: -30%;
    }

    100% {
      left: 100%;
    }
  }

  .transition {
    width: 100%;
  }

  .openseaButton {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #58d4ff;
    color: #ffffff;
    border: none;
    font-size: 50px;
    padding: 10px;
    border-radius: 50px;
    box-shadow: 0 5px #409cbb;
    transition: 0.5s ease;
    padding-left: 15px;
    padding-right: 15px;
    background-image: linear-gradient(to right,
        #ff7d84,
        #ff7d84 16.65%,
        #ff733f 16.65%,
        #ff733f 33.3%,
        #39b6ec 33.3%,
        #39b6ec 49.95%,
        #ecd435 49.95%,
        #ecd435 66.6%,
        #bc3ceb 66.6%,
        #bc3ceb 83.25%,
        #fe8abe 83.25%,
        #fe8abe 100%,
        #E7484F 100%);
    animation: slidebg 2s linear infinite;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .openseaButton:hover {

    color: white;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .discord4{
    display: none;
  }
  .rmFish {
    width: 400px;
    height: 400px;
    animation: fishSwim 40s infinite;
    
  }

  .rmFish4 {
    width: 350px;
    height: 350px;
    animation: fishSwim 30s infinite;
    margin-top: -400px;

  }

  .rmFish2 {
    width: 400px;
    height: 400px;
    animation: fishSwim2 35s infinite;
    margin-top: -300px;
  }

  .rmFish3 {
    width: 400px;
    height: 400px;
    animation: fishSwim2 25s infinite;
    margin-top: -350px;
  }

  @keyframes fishSwim {
    0% {
      transform: translateX(-30vw);
      opacity: 1;
    }

    100% {
      transform: translateX(100vw);
      opacity: 1;

    }

  }

  @keyframes fishSwim2 {
    0% {
      transform: translateX(100vw) scaleX(-1);
      opacity: 1;

    }

    100% {
      transform: translateX(-50vw) scaleX(-1);
      opacity: 1;

    }

  }

  .rmNewMain {
    flex-flow: column nowrap;
  }

  .rmNew2 {
    flex-flow: column nowrap;
    justify-content: center;
    background-color: #01345e91;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    padding: 20px;
    margin-bottom: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .rmQ {
    text-align: left;
    color: #69b0c0;
    font-size: 30px;
  }

  .rmPNew {
    color: white;
    font-size: 20px;
  }

  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: -500px;
    z-index: 10000;
  }

  .homeImages1 {
    animation: Slide_Down1 12s ease infinite;
    z-index: 10000;

  }

  .homeImages2 {
    animation: Slide_Down2 15s ease infinite;
    z-index: 10000;

  }

  @keyframes Slide_Down1 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }

  @keyframes Slide_Down2 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }


  .rightPic {
    width: 400px;
    height: 400px;
    z-index: 1000000;
  }

  .connect2Mobile {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .rmH2 {
    display: none;
  }

  .discordF2 {
    display: none;
  }

  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .rmP2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('https://fishpricsassets.pages.dev/assets/aboutBg.jpg')no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('assets/rmBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Opensea {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('https://fishpricsassets.pages.dev/assets/aboutBg.jpg')no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('assets/mintBg.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
    background-color: black;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;

  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;

  }

  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 60px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    background: -webkit-linear-gradient(#a7eca7, #048604);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    letter-spacing: 5px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ee5bf6;
    margin-bottom: 1%;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 70px;
    color: white;

    text-align: center;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }

  .mintH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .storyCon {
    color: white;
    font-size: 20px;
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
  }

  .storyConOs {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
    margin-bottom: 550px;
  }

  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }


  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyCon2 {
    font-size: 25px;

  }

  .storyCon2Rm {
    font-size: 20px;

  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 70px;
    text-align: center;

  }

  .intro img {
    width: 60%;
    z-index: 100000;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    border: #4086e2 10px solid;

    border-radius: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 14px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    margin-left: auto;
   }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1000;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 50px;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    justify-content: flex-end;
   }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;

    color: #2aacff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }

  .connect div:hover{
    color: white;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }

  .btnfos-0-3-1 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  btnfos-0-3-1:active {
    background-color: #7a1883;

  }

  .btnfos-0-3-1:focus {
    background-color: #7a1883;
  }



  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  .btnfos-0-3:active {
    background-color: #7a1883;

  }

  .btnfos-0-3:focus {
    background-color: #7a1883;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: white;
    font-size: 85px;

    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;

    font-size: 30px;
  }

  .iconsMob {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-top: -50px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  
  }


  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .middleH {
    width: 600px;
    height: 420px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -40px;
    z-index: 100;
  }

  .middleM {
    width: 500px;
    height: 172px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    z-index: 100;
  }

  .middleS {
    width: 400px;
    height: 138px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .middleO {
    width: 400px;
    height: 179px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .middleR {
    width: 500px;
    height: 194px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }


  .wallet2 {
    z-index: 1000;
    background-color: #2aacff;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 200px;
    transition: transform .2s;
    border: none;

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;

  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .artSub {
    color: white;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 3%;

  }

  .artSub:hover {
    transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    background: -webkit-linear-gradient(#2ac7ff, #8729bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 20px;
    padding-bottom: 10px;
   }

  .footer {}

  .logoF {
    width: 12%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
    border-radius: 100%;
    border: 2px solid white;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 100px;
    height: 86px;
  }

  .sop {
    width: 130px;
    height: 59px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
  }

  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 25px;

    cursor: pointer;
    z-index: 10000;

  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 370px;
    height: 370px;
    border-radius: 20px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }

  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }


  .rmBoxMain2 {
    margin-top: 30%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    }

    .videoBg {
      position: absolute;
      top: 0;
      left: 0px;
      max-width: 100%;
      min-height: 100%;
    }

  .bgImg {
    background: url('https://smassets.pages.dev/assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }


  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }

  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1.3, 1.3);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1.3, 1.3);
    }
  }

  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }

  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }

  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }

  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }

  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }

  .teamCon img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }

  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }

  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .twitterTeamDiv {
    background-color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;

  }

  .twitterTeamDiv:hover {
    transform: scale(1.2);
    filter: none;
  }

  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: transform 1s;
    filter: grayscale(100%);
    transition: 0.5s ease;
  }

  .art:hover {
    transform: scale(1.1);
    filter: none;
  }

  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }

  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }

  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }

  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }

  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }

  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }

  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }

  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }

  .lightrope li:last-child:after {
    content: none;
  }

  .lightrope li:first-child {
    margin-left: -40px;
  }

  @-webkit-keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @-webkit-keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @-webkit-keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  @keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }

  .footerIcons img {
    width: 45px;
    height: 45px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .footerIcons img:hover {
    transform: scale(1.3);
    filter: none;
  }

  /* Start https://www.cursors-4u.com */

  /* End https://www.cursors-4u.com */

  /* Basic Reset */

  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .right {
    margin: 5rem;
   }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .stickyGhost {
    width: 250px;
    height: 367px;
    position: sticky;
    position: -webkit-sticky;
    right: 100%;
    margin-top: -40%;
    bottom: 0;
    transform: rotate(65deg);
  }

  .animation-wrapper {
    background: #ace5ee;
    left: 0;
    position: absolute;
    right: 0;
  }

  .water {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .water ul.waves {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .water ul.waves li {
    background-repeat: repeat-x;
  }

  .water ul.waves li.wave-one {
    animation: wave 8.7s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    bottom: 0px;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    background-image: url('https://smassets.pages.dev/assets/wave1.png');
    background-size: contain;
  }

  .water ul.waves li.wave-two {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 10s linear infinite;
    bottom: 0px;
    height: 84px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 9;
    background-image: url(https://i.postimg.cc/P5hv05rh/wave2.png);
    background-size: contain;
  }

  .water ul.waves li.wave-three {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 190px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 8;
    background-image: url(https://i.postimg.cc/63Dyc91k/wave3.png);
    background-size: contain;
  }

  .water ul.waves li.wave-four {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 205px;
    left: 0;
    position: absolute;
    right: 0;
    background-image: url(https://i.postimg.cc/1tg8DgM0/wave4.png);
    background-size: contain;
  }

  .boat {
    animation: boat 3s linear infinite;
    background-repeat: no-repeat;
    bottom: 175px;
    height: 145px;
    left: 50%;
    position: absolute;
    width: 350px;
    height: 389px;
    background-image: url('https://smassets.pages.dev/assets/boat.png');
    background-size: contain;
    -webkit-transform: scaleX(-1);
  }

  .cloud {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 280px;
    height: 165px;
    left: 110px;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .cloud2 {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 450px;
    height: 165px;
    left: 0;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .dolphin {
    animation: fish 15s linear infinite;
    background-repeat: no-repeat;
    bottom: 45px;
    height: 80px;
    left: 20%;
    position: absolute;
    width: 124px;
    z-index: 9;
  }





  /*Animation Keyframe Section*/

  @keyframes wave {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 1920px 0;
    }
  }

  @keyframes boat {
    0% {
      transform: rotate(0);
    }

    50% {
      transform: rotate(-3deg);
    }

    100% {
      transform: rotate(0);
    }
  }

  @keyframes cloud {
    0% {
      left: -30%;
    }

    100% {
      left: 100%;
    }
  }

  .transition {
    width: 100%;
  }

  .openseaButton {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #58d4ff;
    color: #ffffff;
    border: none;
    font-size: 50px;
    padding: 10px;
    border-radius: 50px;
    box-shadow: 0 5px #409cbb;
    transition: 0.5s ease;
    padding-left: 15px;
    padding-right: 15px;
    background-image: linear-gradient(to right,
        #ff7d84,
        #ff7d84 16.65%,
        #ff733f 16.65%,
        #ff733f 33.3%,
        #39b6ec 33.3%,
        #39b6ec 49.95%,
        #ecd435 49.95%,
        #ecd435 66.6%,
        #bc3ceb 66.6%,
        #bc3ceb 83.25%,
        #fe8abe 83.25%,
        #fe8abe 100%,
        #E7484F 100%);
    animation: slidebg 2s linear infinite;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .openseaButton:hover {

    color: white;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .discord4{
    display: none;
  }
  .rmFish {
    width: 400px;
    height: 400px;
    animation: fishSwim 40s infinite;
    
  }

  .rmFish4 {
    width: 350px;
    height: 350px;
    animation: fishSwim 30s infinite;
    margin-top: -400px;

  }

  .rmFish2 {
    width: 400px;
    height: 400px;
    animation: fishSwim2 35s infinite;
    margin-top: -300px;
  }

  .rmFish3 {
    width: 400px;
    height: 400px;
    animation: fishSwim2 25s infinite;
    margin-top: -350px;
  }

  @keyframes fishSwim {
    0% {
      transform: translateX(-30vw);
      opacity: 1;
    }

    100% {
      transform: translateX(100vw);
      opacity: 1;

    }

  }

  @keyframes fishSwim2 {
    0% {
      transform: translateX(100vw) scaleX(-1);
      opacity: 1;

    }

    100% {
      transform: translateX(-50vw) scaleX(-1);
      opacity: 1;

    }

  }

  .rmNewMain {
    flex-flow: column nowrap;
  }

  .rmNew2 {
    flex-flow: column nowrap;
    justify-content: center;
    background-color: #01345e91;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    padding: 20px;
    margin-bottom: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .rmQ {
    text-align: left;
    color: #69b0c0;
    font-size: 30px;
  }

  .rmPNew {
    color: white;
    font-size: 20px;
  }

  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: -500px;
    z-index: 10000;
  }

  .homeImages1 {
    animation: Slide_Down1 12s ease infinite;
    z-index: 10000;

  }

  .homeImages2 {
    animation: Slide_Down2 15s ease infinite;
    z-index: 10000;

  }

  @keyframes Slide_Down1 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }

  @keyframes Slide_Down2 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }


  .rightPic {
    width: 450px;
    height: 450px;
    z-index: 1000000;
  }

  .connect2Mobile {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .rmH2 {
    display: none;
  }

  .discordF2 {
    display: none;
  }

  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .rmP2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('https://fishpricsassets.pages.dev/assets/aboutBg.jpg')no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('assets/rmBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Opensea {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('https://fishpricsassets.pages.dev/assets/aboutBg.jpg')no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('assets/mintBg.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
    background-color: black;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;

  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;

  }

  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 60px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    background: -webkit-linear-gradient(#a7eca7, #048604);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    letter-spacing: 5px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ee5bf6;
    margin-bottom: 1%;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 70px;
    color: white;

    text-align: center;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }

  .mintH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .storyCon {
    color: white;
    font-size: 20px;
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
  }

  .storyConOs {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
    margin-bottom: 550px;
  }

  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }


  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyCon2 {
    font-size: 25px;

  }

  .storyCon2Rm {
    font-size: 20px;

  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 70px;
    text-align: center;

  }

  .intro img {
    width: 60%;
    z-index: 100000;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    border: #4086e2 10px solid;

    border-radius: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 14px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    margin-left: auto;
   }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1000;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 50px;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    justify-content: flex-end;
   }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;

    color: #2aacff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }

  .connect div:hover{
    color: white;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }

  .btnfos-0-3-1 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  btnfos-0-3-1:active {
    background-color: #7a1883;

  }

  .btnfos-0-3-1:focus {
    background-color: #7a1883;
  }



  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  .btnfos-0-3:active {
    background-color: #7a1883;

  }

  .btnfos-0-3:focus {
    background-color: #7a1883;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: white;
    font-size: 85px;

    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;

    font-size: 30px;
  }

  .iconsMob {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-top: -50px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  
  }


  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .middleH {
    width: 700px;
    height: 490px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -3%;
    z-index: 100;
  }

  .middleM {
    width: 500px;
    height: 172px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    z-index: 100;
  }

  .middleS {
    width: 400px;
    height: 138px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .middleO {
    width: 400px;
    height: 179px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .middleR {
    width: 500px;
    height: 194px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }


  .wallet2 {
    z-index: 1000;
    background-color: #2aacff;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 200px;
    transition: transform .2s;
    border: none;

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;

  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .artSub {
    color: white;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 3%;

  }

  .artSub:hover {
    transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    background: -webkit-linear-gradient(#2ac7ff, #8729bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 20px;
    padding-bottom: 10px;
   }

  .footer {}

  .logoF {
    width: 12%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
    border-radius: 100%;
    border: 2px solid white;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 100px;
    height: 86px;
  }

  .sop {
    width: 130px;
    height: 59px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
  }

  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 25px;

    cursor: pointer;
    z-index: 10000;

  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 400px;
    height: 400px;
    border-radius: 20px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }

  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }


  .rmBoxMain2 {
    margin-top: 30%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    }

    .videoBg {
      position: absolute;
      top: 0;
      left: 0px;
      max-width: 100%;
      min-height: 100%;
    }

  .bgImg {
    background: url('https://smassets.pages.dev/assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }


  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }

  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1.3, 1.3);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1.3, 1.3);
    }
  }

  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }

  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }

  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }

  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }

  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }

  .teamCon img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }

  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }

  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .twitterTeamDiv {
    background-color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;

  }

  .twitterTeamDiv:hover {
    transform: scale(1.2);
    filter: none;
  }

  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: transform 1s;
    filter: grayscale(100%);
    transition: 0.5s ease;
  }

  .art:hover {
    transform: scale(1.1);
    filter: none;
  }

  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }

  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }

  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }

  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }

  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }

  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }

  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }

  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }

  .lightrope li:last-child:after {
    content: none;
  }

  .lightrope li:first-child {
    margin-left: -40px;
  }

  @-webkit-keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @-webkit-keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @-webkit-keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  @keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }

  .footerIcons img {
    width: 45px;
    height: 45px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .footerIcons img:hover {
    transform: scale(1.3);
    filter: none;
  }

  /* Start https://www.cursors-4u.com */

  /* End https://www.cursors-4u.com */

  /* Basic Reset */

  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .right {
    margin: 5rem;
   }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .stickyGhost {
    width: 250px;
    height: 367px;
    position: sticky;
    position: -webkit-sticky;
    right: 100%;
    margin-top: -40%;
    bottom: 0;
    transform: rotate(65deg);
  }

  .animation-wrapper {
    background: #ace5ee;
    left: 0;
    position: absolute;
    right: 0;
  }

  .water {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .water ul.waves {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .water ul.waves li {
    background-repeat: repeat-x;
  }

  .water ul.waves li.wave-one {
    animation: wave 8.7s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    bottom: 0px;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    background-image: url('https://smassets.pages.dev/assets/wave1.png');
    background-size: contain;
  }

  .water ul.waves li.wave-two {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 10s linear infinite;
    bottom: 0px;
    height: 84px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 9;
    background-image: url(https://i.postimg.cc/P5hv05rh/wave2.png);
    background-size: contain;
  }

  .water ul.waves li.wave-three {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 190px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 8;
    background-image: url(https://i.postimg.cc/63Dyc91k/wave3.png);
    background-size: contain;
  }

  .water ul.waves li.wave-four {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 205px;
    left: 0;
    position: absolute;
    right: 0;
    background-image: url(https://i.postimg.cc/1tg8DgM0/wave4.png);
    background-size: contain;
  }

  .boat {
    animation: boat 3s linear infinite;
    background-repeat: no-repeat;
    bottom: 175px;
    height: 145px;
    left: 50%;
    position: absolute;
    width: 350px;
    height: 389px;
    background-image: url('https://smassets.pages.dev/assets/boat.png');
    background-size: contain;
    -webkit-transform: scaleX(-1);
  }

  .cloud {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 280px;
    height: 165px;
    left: 110px;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .cloud2 {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 450px;
    height: 165px;
    left: 0;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .dolphin {
    animation: fish 15s linear infinite;
    background-repeat: no-repeat;
    bottom: 45px;
    height: 80px;
    left: 20%;
    position: absolute;
    width: 124px;
    z-index: 9;
  }





  /*Animation Keyframe Section*/

  @keyframes wave {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 1920px 0;
    }
  }

  @keyframes boat {
    0% {
      transform: rotate(0);
    }

    50% {
      transform: rotate(-3deg);
    }

    100% {
      transform: rotate(0);
    }
  }

  @keyframes cloud {
    0% {
      left: -30%;
    }

    100% {
      left: 100%;
    }
  }

  .transition {
    width: 100%;
  }

  .openseaButton {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #58d4ff;
    color: #ffffff;
    border: none;
    font-size: 50px;
    padding: 10px;
    border-radius: 50px;
    box-shadow: 0 5px #409cbb;
    transition: 0.5s ease;
    padding-left: 15px;
    padding-right: 15px;
    background-image: linear-gradient(to right,
        #ff7d84,
        #ff7d84 16.65%,
        #ff733f 16.65%,
        #ff733f 33.3%,
        #39b6ec 33.3%,
        #39b6ec 49.95%,
        #ecd435 49.95%,
        #ecd435 66.6%,
        #bc3ceb 66.6%,
        #bc3ceb 83.25%,
        #fe8abe 83.25%,
        #fe8abe 100%,
        #E7484F 100%);
    animation: slidebg 2s linear infinite;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .openseaButton:hover {

    color: white;
  }
}

@media screen and (min-width: 1920px) and (max-width: 1920px) {
  .discord4{
    display: none;
  }
  .rmFish {
    width: 450px;
    height: 450px;
    animation: fishSwim 40s infinite;
    
  }

  .rmFish4 {
    width: 400px;
    height: 400px;
    animation: fishSwim 30s infinite;
    margin-top: -400px;

  }

  .rmFish2 {
    width: 450px;
    height: 450px;
    animation: fishSwim2 35s infinite;
    margin-top: -300px;
  }

  .rmFish3 {
    width: 420px;
    height: 420px;
    animation: fishSwim2 25s infinite;
    margin-top: -350px;
  }

  @keyframes fishSwim {
    0% {
      transform: translateX(-30vw);
      opacity: 1;
    }

    100% {
      transform: translateX(100vw);
      opacity: 1;

    }

  }

  @keyframes fishSwim2 {
    0% {
      transform: translateX(100vw) scaleX(-1);
      opacity: 1;

    }

    100% {
      transform: translateX(-50vw) scaleX(-1);
      opacity: 1;

    }

  }

  .rmNewMain {
    flex-flow: column nowrap;
  }

  .rmNew2 {
    flex-flow: column nowrap;
    justify-content: center;
    background-color: #01345e91;
    margin-left: auto;
    margin-right: auto;
    width: 750px;
    padding: 20px;
    margin-bottom: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .rmQ {
    text-align: left;
    color: #69b0c0;
    font-size: 45px;
  }

  .rmPNew {
    color: white;
    font-size: 27px;
  }

  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: -500px;
    z-index: 10000;
  }

  .homeImages1 {
    animation: Slide_Down1 12s ease infinite;
    z-index: 10000;

  }

  .homeImages2 {
    animation: Slide_Down2 15s ease infinite;
    z-index: 10000;

  }

  @keyframes Slide_Down1 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }

  @keyframes Slide_Down2 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }


  .rightPic {
    width: 450px;
    height: 450px;
    z-index: 1000000;
  }

  .connect2Mobile {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .rmH2 {
    display: none;
  }

  .discordF2 {
    display: none;
  }

  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .rmP2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .osPic {
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 40px;
    color: #ffffff;
  }

  .discord {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('https://fishpricsassets.pages.dev/assets/aboutBg.jpg')no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('assets/rmBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Opensea {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('https://fishpricsassets.pages.dev/assets/aboutBg.jpg')no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('assets/mintBg.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
     margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
    background-color: black;
    }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;

  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;

  }

  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 60px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    background: -webkit-linear-gradient(#a7eca7, #048604);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    letter-spacing: 5px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ee5bf6;
    margin-bottom: 1%;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 70px;
    color: white;

    text-align: center;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }

  .mintH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .storyCon {
    color: white;
    font-size: 20px;
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
  }

  .storyConOs {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
    margin-bottom: 550px;
  }

  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }


  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyCon2 {
    font-size: 38px;

  }

  .storyCon2Rm {
    font-size: 20px;

  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 70px;
    text-align: center;

  }

  .intro img {
    width: 60%;
    z-index: 100000;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    border: #4086e2 10px solid;

    border-radius: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 26px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 30px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 20px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    margin-left: auto;
   }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord2 {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1000;
  }

  .discord2:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 50px;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    justify-content: flex-end;
   }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;

    color: #2aacff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }

  .connect div:hover{
    color: white;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }

  .btnfos-0-3-1 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 35px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  btnfos-0-3-1:active {
    background-color: #7a1883;

  }

  .btnfos-0-3-1:focus {
    background-color: #7a1883;
  }



  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  .btnfos-0-3:active {
    background-color: #7a1883;

  }

  .btnfos-0-3:focus {
    background-color: #7a1883;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: white;
    font-size: 85px;

    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;

    font-size: 35px;
  }

  .iconsMob {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-top: -30px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  
  }


  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .middleH {
    width: 800px;
    height: 560px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto; 
    margin-top: 80px;
    z-index: 100;
  }

  .middleM {
    width: 600px;
    height: 207px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    z-index: 100;
  }

  .middleS {
    width: 550px;
    height: 189px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .middleO {
    width: 400px;
    height: 179px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .middleR {
    width: 550px;
    height: 189px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }


  .wallet2 {
    z-index: 1000;
    background-color: #2aacff;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    border-radius: 50px;
    width: 280px;
    transition: transform .2s;
    border: none;

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    margin-left: 15px;

  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .artSub {
    color: white;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 3%;

  }

  .artSub:hover {
    transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
  }

  .totalSupply {
    text-align: center;
    font-size: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    background: -webkit-linear-gradient(#2ac7ff, #8729bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 28px;
    padding-bottom: 10px;
   }

  .footer {}

  .logoF {
    width: 12%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
    border-radius: 100%;
    border: 2px solid white;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 100px;
    height: 86px;
  }

  .sop {
    width: 130px;
    height: 59px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
  }

  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 40px;

    cursor: pointer;
    z-index: 10000;

  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 500px;
    height: 500px;
    border-radius: 20px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }

  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }


  .rmBoxMain2 {
    margin-top: 30%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    }

    .videoBg {
      position: absolute;
      top: 0;
      left: 0px;
      max-width: 100%;
      min-height: 100%;
    }

  .bgImg {
    background: url('https://smassets.pages.dev/assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }


  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }

  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1.3, 1.3);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1.3, 1.3);
    }
  }

  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }

  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }

  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }

  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }

  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }

  .teamCon img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }

  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }

  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .twitterTeamDiv {
    background-color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;

  }

  .twitterTeamDiv:hover {
    transform: scale(1.2);
    filter: none;
  }

  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: transform 1s;
    filter: grayscale(100%);
    transition: 0.5s ease;
  }

  .art:hover {
    transform: scale(1.1);
    filter: none;
  }

  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }

  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }

  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }

  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }

  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }

  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }

  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }

  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }

  .lightrope li:last-child:after {
    content: none;
  }

  .lightrope li:first-child {
    margin-left: -40px;
  }

  @-webkit-keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @-webkit-keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @-webkit-keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  @keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }

  .footerIcons img {
    width: 45px;
    height: 45px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .footerIcons img:hover {
    transform: scale(1.3);
    filter: none;
  }

  /* Start https://www.cursors-4u.com */

  /* End https://www.cursors-4u.com */

  /* Basic Reset */

  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .right {
    margin: 5rem;
   }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .stickyGhost {
    width: 250px;
    height: 367px;
    position: sticky;
    position: -webkit-sticky;
    right: 100%;
    margin-top: -40%;
    bottom: 0;
    transform: rotate(65deg);
  }

  .animation-wrapper {
    background: #ace5ee;
    left: 0;
    position: absolute;
    right: 0;
  }

  .water {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .water ul.waves {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .water ul.waves li {
    background-repeat: repeat-x;
  }

  .water ul.waves li.wave-one {
    animation: wave 8.7s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    bottom: 0px;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    background-image: url('https://smassets.pages.dev/assets/wave1.png');
    background-size: contain;
  }

  .water ul.waves li.wave-two {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 10s linear infinite;
    bottom: 0px;
    height: 84px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 9;
    background-image: url(https://i.postimg.cc/P5hv05rh/wave2.png);
    background-size: contain;
  }

  .water ul.waves li.wave-three {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 190px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 8;
    background-image: url(https://i.postimg.cc/63Dyc91k/wave3.png);
    background-size: contain;
  }

  .water ul.waves li.wave-four {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 205px;
    left: 0;
    position: absolute;
    right: 0;
    background-image: url(https://i.postimg.cc/1tg8DgM0/wave4.png);
    background-size: contain;
  }

  .boat {
    animation: boat 3s linear infinite;
    background-repeat: no-repeat;
    bottom: 175px;
    height: 145px;
    left: 50%;
    position: absolute;
    width: 350px;
    height: 389px;
    background-image: url('https://smassets.pages.dev/assets/boat.png');
    background-size: contain;
    -webkit-transform: scaleX(-1);
  }

  .cloud {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 280px;
    height: 165px;
    left: 110px;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .cloud2 {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 450px;
    height: 165px;
    left: 0;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .dolphin {
    animation: fish 15s linear infinite;
    background-repeat: no-repeat;
    bottom: 45px;
    height: 80px;
    left: 20%;
    position: absolute;
    width: 124px;
    z-index: 9;
  }





  /*Animation Keyframe Section*/

  @keyframes wave {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 1920px 0;
    }
  }

  @keyframes boat {
    0% {
      transform: rotate(0);
    }

    50% {
      transform: rotate(-3deg);
    }

    100% {
      transform: rotate(0);
    }
  }

  @keyframes cloud {
    0% {
      left: -30%;
    }

    100% {
      left: 100%;
    }
  }

  .transition {
    width: 100%;
  }

  .openseaButton {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #58d4ff;
    color: #ffffff;
    border: none;
    font-size: 50px;
    padding: 10px;
    border-radius: 50px;
    box-shadow: 0 5px #409cbb;
    transition: 0.5s ease;
    padding-left: 15px;
    padding-right: 15px;
    background-image: linear-gradient(to right,
        #ff7d84,
        #ff7d84 16.65%,
        #ff733f 16.65%,
        #ff733f 33.3%,
        #39b6ec 33.3%,
        #39b6ec 49.95%,
        #ecd435 49.95%,
        #ecd435 66.6%,
        #bc3ceb 66.6%,
        #bc3ceb 83.25%,
        #fe8abe 83.25%,
        #fe8abe 100%,
        #E7484F 100%);
    animation: slidebg 2s linear infinite;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .openseaButton:hover {

    color: white;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .discord4{
    display: none;
  }
  .rmFish {
    width: 550px;
    height: 550px;
    animation: fishSwim 40s infinite;
    
  }

  .rmFish4 {
    width: 550px;
    height: 550px;
    animation: fishSwim 30s infinite;
    margin-top: -400px;

  }

  .rmFish2 {
    width: 550px;
    height: 550px;
    animation: fishSwim2 35s infinite;
    margin-top: -300px;
  }

  .rmFish3 {
    width: 560px;
    height: 560px;
    animation: fishSwim2 25s infinite;
    margin-top: -350px;
  }

  @keyframes fishSwim {
    0% {
      transform: translateX(-30vw);
      opacity: 1;
    }

    100% {
      transform: translateX(100vw);
      opacity: 1;

    }

  }

  @keyframes fishSwim2 {
    0% {
      transform: translateX(100vw) scaleX(-1);
      opacity: 1;

    }

    100% {
      transform: translateX(-50vw) scaleX(-1);
      opacity: 1;

    }

  }

  .rmNewMain {
    flex-flow: column nowrap;
  }

  .rmNew2 {
    flex-flow: column nowrap;
    justify-content: center;
    background-color: #01345e91;
    margin-left: auto;
    margin-right: auto;
    width: 900px;
    padding: 25px;
    margin-bottom: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .rmQ {
    text-align: left;
    color: #69b0c0;
    font-size: 55px;
  }

  .rmPNew {
    color: white;
    font-size: 35px;
  }

  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: -500px;
    z-index: 10000;
  }

  .homeImages1 {
    animation: Slide_Down1 12s ease infinite;
    z-index: 10000;

  }

  .homeImages2 {
    animation: Slide_Down2 15s ease infinite;
    z-index: 10000;

  }

  @keyframes Slide_Down1 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }

  @keyframes Slide_Down2 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }


  .rightPic {
    width: 450px;
    height: 450px;
    z-index: 1000000;
  }

  .connect2Mobile {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .rmH2 {
    display: none;
  }

  .discordF2 {
    display: none;
  }

  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .rmP2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .osPic {
    width: 55px;
    height: 55px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 50px;
    color: #ffffff;
  }

  .discord {
    display: none;
  }

  .discord2 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('https://fishpricsassets.pages.dev/assets/aboutBg.jpg')no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('assets/rmBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Opensea {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('https://fishpricsassets.pages.dev/assets/aboutBg.jpg')no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('assets/mintBg.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
     margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
    background-color: black;
    }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;

  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;

  }

  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 60px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    background: -webkit-linear-gradient(#a7eca7, #048604);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    letter-spacing: 5px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ee5bf6;
    margin-bottom: 1%;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 70px;
    color: white;

    text-align: center;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }

  .mintH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .storyCon {
    color: white;
    font-size: 20px;
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
  }

  .storyConOs {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
    margin-bottom: 550px;
  }

  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }


  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyCon2 {
    font-size: 50px;

  }

  .storyCon2Rm {
    font-size: 20px;

  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 70px;
    text-align: center;

  }

  .intro img {
    width: 60%;
    z-index: 100000;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    border: #4086e2 10px solid;

    border-radius: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 30px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 37px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 22px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    margin-left: auto;
   }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord3 {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1000;
  }

  .discord3:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 50px;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    justify-content: flex-end;
   }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;

    color: #2aacff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }

  .connect div:hover{
    color: white;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 50px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 85px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 50px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 85px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }

  .btnfos-0-3-1 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 60px;
    border-radius: 50px;
    padding-left: 40px;
    padding-right: 40px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  btnfos-0-3-1:active {
    background-color: #7a1883;

  }

  .btnfos-0-3-1:focus {
    background-color: #7a1883;
  }



  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 38px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  .btnfos-0-3:active {
    background-color: #7a1883;

  }

  .btnfos-0-3:focus {
    background-color: #7a1883;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: white;
    font-size: 95px;

    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;

    font-size: 50px;
  }

  .iconsMob {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-top: -30px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  
  }


  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .middleH {
    width: 1150px;
    height: 805px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto; 
    margin-top: 80px;
    z-index: 100;
  }

  .middleM {
    width: 700px;
    height: 241px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    z-index: 100;
  }

  .middleS {
    width: 680px;
    height: 234px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .middleO {
    width: 400px;
    height: 179px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .middleR {
    width: 750px;
    height: 258px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }


  .wallet2 {
    z-index: 1000;
    background-color: #2aacff;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 50px;
    border-radius: 100px;
    width: 460px;
    transition: transform .2s;
    border: none;

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    margin-left: 15px;

  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .artSub {
    color: white;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 3%;

  }

  .artSub:hover {
    transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
  }

  .totalSupply {
    text-align: center;
    font-size: 95px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    background: -webkit-linear-gradient(#2ac7ff, #8729bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 37px;
    padding-bottom: 10px;
   }

  .footer {}

  .logoF {
    width: 12%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
    border-radius: 100%;
    border: 2px solid white;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 100px;
    height: 86px;
  }

  .sop {
    width: 130px;
    height: 59px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
  }

  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 52px;

    cursor: pointer;
    z-index: 10000;

  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 600px;
    height: 600px;
    border-radius: 20px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }

  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }


  .rmBoxMain2 {
    margin-top: 30%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    }

    .videoBg {
      position: absolute;
      top: 0;
      left: 0px;
      max-width: 100%;
      min-height: 100%;
    }

  .bgImg {
    background: url('https://smassets.pages.dev/assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }


  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }

  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1.3, 1.3);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1.3, 1.3);
    }
  }

  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }

  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }

  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }

  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }

  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }

  .teamCon img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }

  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }

  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .twitterTeamDiv {
    background-color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;

  }

  .twitterTeamDiv:hover {
    transform: scale(1.2);
    filter: none;
  }

  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: transform 1s;
    filter: grayscale(100%);
    transition: 0.5s ease;
  }

  .art:hover {
    transform: scale(1.1);
    filter: none;
  }

  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }

  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }

  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }

  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }

  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }

  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }

  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }

  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }

  .lightrope li:last-child:after {
    content: none;
  }

  .lightrope li:first-child {
    margin-left: -40px;
  }

  @-webkit-keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @-webkit-keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @-webkit-keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  @keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }

  .footerIcons img {
    width: 45px;
    height: 45px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .footerIcons img:hover {
    transform: scale(1.3);
    filter: none;
  }

  /* Start https://www.cursors-4u.com */

  /* End https://www.cursors-4u.com */

  /* Basic Reset */

  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .right {
    margin: 5rem;
   }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .stickyGhost {
    width: 250px;
    height: 367px;
    position: sticky;
    position: -webkit-sticky;
    right: 100%;
    margin-top: -40%;
    bottom: 0;
    transform: rotate(65deg);
  }

  .animation-wrapper {
    background: #ace5ee;
    left: 0;
    position: absolute;
    right: 0;
  }

  .water {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .water ul.waves {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .water ul.waves li {
    background-repeat: repeat-x;
  }

  .water ul.waves li.wave-one {
    animation: wave 8.7s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    bottom: 0px;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    background-image: url('https://smassets.pages.dev/assets/wave1.png');
    background-size: contain;
  }

  .water ul.waves li.wave-two {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 10s linear infinite;
    bottom: 0px;
    height: 84px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 9;
    background-image: url(https://i.postimg.cc/P5hv05rh/wave2.png);
    background-size: contain;
  }

  .water ul.waves li.wave-three {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 190px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 8;
    background-image: url(https://i.postimg.cc/63Dyc91k/wave3.png);
    background-size: contain;
  }

  .water ul.waves li.wave-four {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 205px;
    left: 0;
    position: absolute;
    right: 0;
    background-image: url(https://i.postimg.cc/1tg8DgM0/wave4.png);
    background-size: contain;
  }

  .boat {
    animation: boat 3s linear infinite;
    background-repeat: no-repeat;
    bottom: 175px;
    height: 145px;
    left: 50%;
    position: absolute;
    width: 350px;
    height: 389px;
    background-image: url('https://smassets.pages.dev/assets/boat.png');
    background-size: contain;
    -webkit-transform: scaleX(-1);
  }

  .cloud {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 280px;
    height: 165px;
    left: 110px;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .cloud2 {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 450px;
    height: 165px;
    left: 0;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .dolphin {
    animation: fish 15s linear infinite;
    background-repeat: no-repeat;
    bottom: 45px;
    height: 80px;
    left: 20%;
    position: absolute;
    width: 124px;
    z-index: 9;
  }





  /*Animation Keyframe Section*/

  @keyframes wave {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 1920px 0;
    }
  }

  @keyframes boat {
    0% {
      transform: rotate(0);
    }

    50% {
      transform: rotate(-3deg);
    }

    100% {
      transform: rotate(0);
    }
  }

  @keyframes cloud {
    0% {
      left: -30%;
    }

    100% {
      left: 100%;
    }
  }

  .transition {
    width: 100%;
  }

  .openseaButton {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #58d4ff;
    color: #ffffff;
    border: none;
    font-size: 50px;
    padding: 10px;
    border-radius: 50px;
    box-shadow: 0 5px #409cbb;
    transition: 0.5s ease;
    padding-left: 15px;
    padding-right: 15px;
    background-image: linear-gradient(to right,
        #ff7d84,
        #ff7d84 16.65%,
        #ff733f 16.65%,
        #ff733f 33.3%,
        #39b6ec 33.3%,
        #39b6ec 49.95%,
        #ecd435 49.95%,
        #ecd435 66.6%,
        #bc3ceb 66.6%,
        #bc3ceb 83.25%,
        #fe8abe 83.25%,
        #fe8abe 100%,
        #E7484F 100%);
    animation: slidebg 2s linear infinite;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .openseaButton:hover {

    color: white;
  }
}

@media screen and (min-width: 3840px) {
  .discord4{
    display: none;
  }
  .rmFish {
    width: 800px;
    height: 800px;
    animation: fishSwim 40s infinite;
    
  }

  .rmFish4 {
    width: 750px;
    height: 750px;
    animation: fishSwim 30s infinite;
    margin-top: -400px;

  }

  .rmFish2 {
    width: 800px;
    height: 800px;
    animation: fishSwim2 35s infinite;
    margin-top: -300px;
  }

  .rmFish3 {
    width: 800px;
    height: 800px;
    animation: fishSwim2 25s infinite;
    margin-top: -350px;
  }

  @keyframes fishSwim {
    0% {
      transform: translateX(-30vw);
      opacity: 1;
    }

    100% {
      transform: translateX(100vw);
      opacity: 1;

    }

  }

  @keyframes fishSwim2 {
    0% {
      transform: translateX(100vw) scaleX(-1);
      opacity: 1;

    }

    100% {
      transform: translateX(-50vw) scaleX(-1);
      opacity: 1;

    }

  }

  .rmNewMain {
    flex-flow: column nowrap;
  }

  .rmNew2 {
    flex-flow: column nowrap;
    justify-content: center;
    background-color: #01345e91;
    margin-left: auto;
    margin-right: auto;
    width: 1300px;
    padding: 25px;
    margin-bottom: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 8px solid white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 60px;
 
  }

  .rmQ {
    text-align: left;
    color: #69b0c0;
    font-size: 80px;
  }

  .rmPNew {
    color: white;
    font-size: 55px;
  }

  .homeImages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: -500px;
    z-index: 10000;
  }

  .homeImages1 {
    animation: Slide_Down1 12s ease infinite;
    z-index: 10000;

  }

  .homeImages2 {
    animation: Slide_Down2 15s ease infinite;
    z-index: 10000;

  }

  @keyframes Slide_Down1 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }

  @keyframes Slide_Down2 {
    0% {
      transform: translateY(-5%);
      opacity: 1;
    }

    50% {
      transform: translateY(20%);
    }

    100% {
      transform: translateY(-5%);
    }

  }


  .rightPic {
    width: 450px;
    height: 450px;
    z-index: 1000000;
  }

  .connect2Mobile {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .rmH2 {
    display: none;
  }

  .discordF2 {
    display: none;
  }

  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .rmP2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .osPic {
    width: 75px;
    height: 75px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 70px;
    color: #ffffff;
  }

  .discord {
    display: none;
  }

  .discord2 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('https://fishpricsassets.pages.dev/assets/aboutBg.jpg')no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('assets/rmBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Opensea {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.09839910085127801) 51%, rgba(0, 0, 0, 0.902320669478729) 100%), url('https://fishpricsassets.pages.dev/assets/aboutBg.jpg')no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('assets/mintBg.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
     margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
    background-color: black;
    }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;

  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;

  }

  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 60px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    background: -webkit-linear-gradient(#a7eca7, #048604);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    letter-spacing: 5px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ee5bf6;
    margin-bottom: 1%;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 70px;
    color: white;

    text-align: center;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }

  .mintH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .storyCon {
    color: white;
    font-size: 20px;
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
  }

  .storyConOs {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
    margin-bottom: 550px;
  }

  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }

  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }


  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyCon2 {
    font-size: 80px;

  }

  .storyCon2Rm {
    font-size: 20px;

  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 70px;
    text-align: center;

  }

  .intro img {
    width: 60%;
    z-index: 100000;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    border: #4086e2 15px solid;

    border-radius: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 40px;
    color: #e0706c;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 55px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 35px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    margin-left: auto;
   }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord4 {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    z-index: 1000;
  }

  .discord4:hover {
    transform: scale(1.1);
  }

  .discord3{
    display: none;
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 50px;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    justify-content: flex-end;
   }

  .connect div {
    margin-left: 20px;
    margin-right: 20px;

    color: #2aacff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }

  .connect div:hover{
    color: white;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 70px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 120px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 70px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 120px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }

  .btnfos-0-3-1 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 80px;
    border-radius: 90px;
    padding-left: 40px;
    padding-right: 40px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  btnfos-0-3-1:active {
    background-color: #7a1883;

  }

  .btnfos-0-3-1:focus {
    background-color: #7a1883;
  }



  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 80px;
    border-radius: 90px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;

    transition: transform .2s;
  }

  .btnfos-0-3:active {
    background-color: #7a1883;

  }

  .btnfos-0-3:focus {
    background-color: #7a1883;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: white;
    font-size: 150px;

    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;

    font-size: 70px;
  }

  .iconsMob {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
     width: 100%;
    padding-left: 5%;
    padding-right: 5%;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  
  }


  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .middleH {
    width: 1800px;
    height: 1260px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto; 
    margin-top: 80px;
    z-index: 100;
  }

  .middleM {
    width: 1100px;
    height: 379px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    z-index: 100;
  }

  .middleS {
    width: 1000px;
    height: 344px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .middleO {
    width: 400px;
    height: 179px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  .middleR {
    width: 1000px;
    height: 344px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }


  .wallet2 {
    z-index: 1000;
    background-color: #2aacff;
    color: #ffffff;
    padding: 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 70px;
    border-radius: 100px;
    width: 650px;
    transition: transform .2s;
    border: none;

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    margin-left: 15px;

  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .artSub {
    color: white;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 3%;

  }

  .artSub:hover {
    transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
  }

  .totalSupply {
    text-align: center;
    font-size: 160px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;

    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    background: -webkit-linear-gradient(#2ac7ff, #8729bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 67px;
    padding-bottom: 10px;
   }

  .footer {}

  .logoF {
    width: 12%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
    border-radius: 100%;
    border: 2px solid white;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 100px;
    height: 86px;
  }

  .sop {
    width: 130px;
    height: 59px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
  }

  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 70px;
    cursor: pointer;
    z-index: 10000;

  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 800px;
    height: 800px;
    border-radius: 20px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }

  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }


  .rmBoxMain2 {
    margin-top: 30%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    }

    .videoBg {
      position: absolute;
      top: 0;
      left: 0px;
      max-width: 100%;
      min-height: 100%;
    }

  .bgImg {
    background: url('https://smassets.pages.dev/assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }


  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }

  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    animation: zoominoutsinglefeatured 5s infinite;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1.3, 1.3);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1.3, 1.3);
    }
  }

  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }

  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }

  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }

  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }

  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }

  .teamCon img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;

  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }

  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }

  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .twitterTeamDiv {
    background-color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;

  }

  .twitterTeamDiv:hover {
    transform: scale(1.2);
    filter: none;
  }

  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: transform 1s;
    filter: grayscale(100%);
    transition: 0.5s ease;
  }

  .art:hover {
    transform: scale(1.1);
    filter: none;
  }

  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }

  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }

  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }

  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }

  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }

  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }

  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }

  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }

  .lightrope li:last-child:after {
    content: none;
  }

  .lightrope li:first-child {
    margin-left: -40px;
  }

  @-webkit-keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @keyframes flash-1 {

    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }

    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }

  @-webkit-keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @keyframes flash-2 {

    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }

    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }

  @-webkit-keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  @keyframes flash-3 {

    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }

    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }

  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }

  .footerIcons img {
    width: 45px;
    height: 45px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .footerIcons img:hover {
    transform: scale(1.3);
    filter: none;
  }

  /* Start https://www.cursors-4u.com */

  /* End https://www.cursors-4u.com */

  /* Basic Reset */

  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .right {
    margin: 5rem;
   }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .stickyGhost {
    width: 250px;
    height: 367px;
    position: sticky;
    position: -webkit-sticky;
    right: 100%;
    margin-top: -40%;
    bottom: 0;
    transform: rotate(65deg);
  }

  .animation-wrapper {
    background: #ace5ee;
    left: 0;
    position: absolute;
    right: 0;
  }

  .water {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .water ul.waves {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .water ul.waves li {
    background-repeat: repeat-x;
  }

  .water ul.waves li.wave-one {
    animation: wave 8.7s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    bottom: 0px;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    background-image: url('https://smassets.pages.dev/assets/wave1.png');
    background-size: contain;
  }

  .water ul.waves li.wave-two {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 10s linear infinite;
    bottom: 0px;
    height: 84px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 9;
    background-image: url(https://i.postimg.cc/P5hv05rh/wave2.png);
    background-size: contain;
  }

  .water ul.waves li.wave-three {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 190px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 8;
    background-image: url(https://i.postimg.cc/63Dyc91k/wave3.png);
    background-size: contain;
  }

  .water ul.waves li.wave-four {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 205px;
    left: 0;
    position: absolute;
    right: 0;
    background-image: url(https://i.postimg.cc/1tg8DgM0/wave4.png);
    background-size: contain;
  }

  .boat {
    animation: boat 3s linear infinite;
    background-repeat: no-repeat;
    bottom: 175px;
    height: 145px;
    left: 50%;
    position: absolute;
    width: 350px;
    height: 389px;
    background-image: url('https://smassets.pages.dev/assets/boat.png');
    background-size: contain;
    -webkit-transform: scaleX(-1);
  }

  .cloud {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 280px;
    height: 165px;
    left: 110px;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .cloud2 {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 450px;
    height: 165px;
    left: 0;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }

  .dolphin {
    animation: fish 15s linear infinite;
    background-repeat: no-repeat;
    bottom: 45px;
    height: 80px;
    left: 20%;
    position: absolute;
    width: 124px;
    z-index: 9;
  }





  /*Animation Keyframe Section*/

  @keyframes wave {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 1920px 0;
    }
  }

  @keyframes boat {
    0% {
      transform: rotate(0);
    }

    50% {
      transform: rotate(-3deg);
    }

    100% {
      transform: rotate(0);
    }
  }

  @keyframes cloud {
    0% {
      left: -30%;
    }

    100% {
      left: 100%;
    }
  }

  .transition {
    width: 100%;
  }

  .openseaButton {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #58d4ff;
    color: #ffffff;
    border: none;
    font-size: 50px;
    padding: 10px;
    border-radius: 50px;
    box-shadow: 0 5px #409cbb;
    transition: 0.5s ease;
    padding-left: 15px;
    padding-right: 15px;
    background-image: linear-gradient(to right,
        #ff7d84,
        #ff7d84 16.65%,
        #ff733f 16.65%,
        #ff733f 33.3%,
        #39b6ec 33.3%,
        #39b6ec 49.95%,
        #ecd435 49.95%,
        #ecd435 66.6%,
        #bc3ceb 66.6%,
        #bc3ceb 83.25%,
        #fe8abe 83.25%,
        #fe8abe 100%,
        #E7484F 100%);
    animation: slidebg 2s linear infinite;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .openseaButton:hover {

    color: white;
  }
}